import React, {Component} from 'react'
import {Button, Container} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

class NotFound extends Component {
  render () {
    return <Container text textAlign='center'>
      <h1>La pagina no existe</h1>
      <Button as={Link} to='/'>Volver al inicio</Button>
    </Container>
  }
}

export default NotFound
