import React from "react";
import {Dropdown, Form} from "semantic-ui-react";
import {stringsToDropdownOption} from "../services/ComputerStatus";


export const ComputerItemDropdown = (props) => {

    return <Form.Dropdown value={props.value} selection
                          search
                          allowAdditions={true}
                          options={stringsToDropdownOption(props.options)}
                          onChange={props.whenSelect}
                          onAddItem={(event,data) => props.whenAddingElement(data.value)}/>

}