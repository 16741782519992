import React from 'react';
import {Redirect, withRouter} from "react-router-dom";
import routes from "../Routes";
import {authService} from "../services/AuthService";

export function Token(props) {
    const {match: {params}} = props;

    authService.saveToken(params.token);
    const urlToRedirect = localStorage.getItem("URL_TO_REDIRECT");
    localStorage.removeItem("URL_TO_REDIRECT");
    return <Redirect to={urlToRedirect || routes.computers}/>;
}

export default withRouter(Token)
