import React, {useState} from 'react';
import {Button, Checkbox, Confirm, Container, Header, Popup, Segment, Table} from "semantic-ui-react";
import HardwareAppAPI from "../services/HardwareAppAPI";

const PayrollReportPage = () => {
    const ASEGURADA = 'Asegurada'
    const NO_ASEGURADA = 'No asegurada'
    const DAR_DE_BAJA = 'Dar de Baja'
    const DANDO_DE_BAJA = 'Dando de Baja'

    const [openConfirm, setOpenConfirm] = useState(false)
    const [loading, setLoading] = useState(false)

    const [includeDate, setIncludeDate] = useState(false)
    const [selectedStates, setSelectedStates] = useState([ASEGURADA])

    const handleConfirm = () => { setOpenConfirm(false) }
    const downloadPayrollReport = () => {
        setLoading(true)
        HardwareAppAPI.getPayrollReport(selectedStates, includeDate)
            .then(response => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.blob);
            link.setAttribute('download', response.filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch(({status, message}) => {
            setOpenConfirm(true)
        }).finally(() => {setLoading(false)})
    }



    const includesState = (aState) => { return selectedStates.includes(aState) }
    const handleChangeInState = (aState) => {
        let currentStates = [...selectedStates]
        if (includesState(aState)) {
            setSelectedStates(currentStates.filter(state => state !== aState))
        } else {
            setSelectedStates(currentStates.concat([aState]))
        }
    }

    function disableCSVDownload() {
        return loading || !selectedStates.length;
    }

    return (<Container>
        <Segment className={'SegmentColor Overflow'} textAlign='left'>
            <h1>Reporte de nómina</h1>
                <Header as='h5' attached='top'> Personalizar Columnas </Header>
                <Segment basic> <Checkbox label='Incluir Fecha' checked={includeDate} onChange={(e, data) => setIncludeDate(data.checked)}/></Segment>

                <Header as='h5' attached='top'>Editar los Estados Incluidos</Header>
                <Segment.Group horizontal>
                    <Segment><Checkbox label='Aseguradas' checked={includesState(ASEGURADA)} onChange={(e, data) => handleChangeInState(ASEGURADA)}/></Segment>
                    <Segment><Checkbox label='No Aseguradas' checked={includesState(NO_ASEGURADA)} onChange={(e, data) => handleChangeInState(NO_ASEGURADA)}/></Segment>
                    <Segment><Checkbox label='Dar de Baja' checked={includesState(DAR_DE_BAJA)} onChange={(e, data) => handleChangeInState(DAR_DE_BAJA)}/></Segment>
                    <Segment><Checkbox label='Dando de Baja' checked={includesState(DANDO_DE_BAJA)} onChange={(e, data) => handleChangeInState(DANDO_DE_BAJA)}/></Segment>
                </Segment.Group>
            <Popup content={ loading ? 'Descargando' : 'Seleccionar un Estado'} open={disableCSVDownload()} position='left center'
                trigger={<>
                    <Button floated='right' color='green' onClick={downloadPayrollReport} disabled={disableCSVDownload()}>Descargar</Button>
                </>
            }/>
        </Segment>
        <Confirm
            content={"Hubo un error. Intente nuevamente."}
            open={openConfirm}
            cancelButton={null}
            onConfirm={handleConfirm}
        />
    </Container>)
}

export default PayrollReportPage;

