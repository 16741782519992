import React, {useState} from 'react'
import {Button, Header, Icon, Modal} from 'semantic-ui-react'
function ModalConfirmDelete({trigger, header, confirmDeleteFunction, modalContent}) {
    const [open, setOpen] = useState(false)
    const confirmedRemoval = () => {
        confirmDeleteFunction()
        setOpen(false)
    };
    return (
        <Modal
            dimmer= 'blurring'
            closeIcon
            open={open}
            trigger={trigger}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            <Header icon='delete' content={header} />
            <Modal.Content>
                {modalContent}
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> Cancelar
                </Button>
                <Button color='green' onClick={() => confirmedRemoval()}>
                    <Icon name='checkmark' /> Confirmar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ModalConfirmDelete
