import React from 'react'
import {Accordion, Button, Confirm, Container, Form, Icon, Segment} from "semantic-ui-react";
import HardwareAppAPI from "../services/HardwareAppAPI";
import EncryptedSelector from "./EncryptedSelector";
import {currentDate} from "../App";
import moment from "moment";
import DropdownAutocomplete from "./DropdownAutocomplete";
import DateWarningMessages from "./DateWarningMessages";
import {BACK_UP, stringsToDropdownOption, stringToDropdownOption} from "../services/ComputerStatus";
import EncryptionStates from "../services/EncryptionStates";
import {withRouter} from "react-router-dom";
import {DateInput} from "semantic-ui-calendar-react";
import "../Css/Buttons.css"
import {SecondaryTrashButton} from "./SecondaryTrashButton";


const MESSAGE_SERVER_ERROR = "Hubo un problema en el servidor al querer agregar las computadoras.";

class AddComputer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    emptyComputer = {
        model: {name: "", brand: ""},
        network_card: "",
        serial_number: "",
        encryption: EncryptionStates.NO_ENCRYPTION,
        cost: "",
        date_of_purchase: currentDate(),
        owner: '10Pines',
        status: BACK_UP,
        location: '',
        pine_assigned_name: "",
    }

    initialState = {
        computer: this.emptyComputer,
        activeAddComputerForm: false,
        pines: [],
        batchComputersInfo: [],
        models: [],
        brands: this.props.computersBrands,
        network_cards: this.props.computersNetworkCards
    }

    render() {
        const computer = this.state.computer
        return <div>
            <Accordion styled fluid>
                <Accordion.Title active={this.state.activeAddComputerForm}
                                 onClick={() => this.setState({activeAddComputerForm: !this.state.activeAddComputerForm})}>
                    <Icon name='dropdown'/>
                    Agregar computadora
                </Accordion.Title>
                <Accordion.Content active={this.state.activeAddComputerForm}>
                    <Segment raised>
                        <Form>
                            <Form.Group widths='equal'>
                                {this.createFormDropdownAutocomplete(stringsToDropdownOption(this.state.brands), computer.model.brand, this.updateBrand, 'brands', 'Marca', true, (value) => this.addElementToStateLists('brands', value))}
                                {this.createFormDropdownAutocomplete(stringsToDropdownOption(this.state.models), computer.model.name, this.updateModel, 'models', 'Modelo', true, (value) => this.addElementToStateLists('models', value))}
                                <DateInput closable={true} label='Fecha de compra'
                                           pickerStyle={{display: "block"}}
                                           placeholder="Fecha de compra"
                                           value={computer.date_of_purchase}
                                           onChange={(event, date) => this.handleComputerChange('date_of_purchase', date.value)}/>
                                <SecondaryTrashButton
                                    onClick={() => this.handleComputerChange('date_of_purchase', "")}/>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                {this.createFormDropdownAutocomplete(stringsToDropdownOption(this.state.network_cards), computer.network_card, this.updateNetworkCard, 'network_card', 'Placa de Red', true, (value) => this.addElementToStateLists('network_cards', value))}
                                {this.createFieldForForm('number', 'cost', 'Costo', 'Costo', computer.cost, false, 0)}
                                {this.createFieldForForm('text', 'owner', 'Dueño', 'Dueño', computer.owner, true)}
                            </Form.Group>
                            {this.state.batchComputersInfo.map(data => this.computerInformationRow(data.serial_number, data.pine_assigned_name, data.location))}
                            {this.computerInformationRow(computer.serial_number, computer.pine_assigned_name, computer.location)}
                            <Form.Group>
                                <Container>
                                    <EncryptedSelector encryption={computer.encryption}
                                                       whenSelect={(value) => this.handleComputerChange('encryption', value)}/>
                                </Container>
                                <Button floated='right' onClick={() => this.deleteLastRowSpecificInformation()} disabled={this.state.batchComputersInfo.length === 0}>-</Button>
                                <Button floated='right' onClick={() => this.addRowSpecificInformation()} disabled={this.state.computer.serial_number === ""}>+</Button>

                                <Button type='submit' floated='right' color='green'
                                            onClick={() => this.handleSubmit()}>Agregar</Button>

                            </Form.Group>
                            <DateWarningMessages dateOfPurchase={moment(computer.date_of_purchase, "DD-MM-YYYY")}/>
                        </Form>
                    </Segment>
                </Accordion.Content>
            </Accordion>
        </div>
    }

    updateAssignment = (assignment_name) => {
        if(this.state.computer.location === ''){
            this.handleComputerChange('location',`Casa de ${assignment_name}`)
        }
        return this.handleComputerChange('pine_assigned_name', assignment_name);
    };

    updateModel = (name) => this.handleComputerChange('model', {name, brand: this.state.computer.model.brand});

    updateBrand = (brand) => {
        const models = brand === "" ? [] : this.props.brandsWithModels[brand]
        this.setState({models})
        this.handleComputerChange('model', {name: "", brand});
    }

    updateNetworkCard = (networkCard) => this.handleComputerChange('network_card', networkCard);


    handleComputerChange(keyOfState, value) {
        this.setState(prevState => ({
            computer: {
                ...prevState.computer,
                [keyOfState]: value,
            }
        }))
    }
    computerInformationRow(serial_number, pine_assigned_name, location) {
        return <Form.Group widths='equal'>
            {this.createFieldForForm('text', 'serial_number', 'Número de serie', 'Número de serie', serial_number, false)}
            {this.createFormDropdownAutocomplete(this.pinesToPineName(this.state.pines), pine_assigned_name, this.updateAssignment, 'pines', 'Asignación', true, this.addPine)}
            {this.createFromLocation(location)}
        </Form.Group>
    }
    addRowSpecificInformation = () => {
        const computer = this.cleanSerialNumberAssignedAndLocation(this.state.computer)
        this.setState({batchComputersInfo: this.detailsAllComputers(), computer })
    }
    deleteLastRowSpecificInformation = () => {
        const computers =  [...this.state.batchComputersInfo]
        const lastSpecificInformationAdded = computers.pop()
        const currentComputer = {
            ...this.state.computer,
            ...this.extractBatchInfo(lastSpecificInformationAdded)
        }
        this.setState({batchComputersInfo: computers, computer: currentComputer})
    }

    detailsAllComputers() {
        const informationCurrentComputer = this.extractBatchInfo(this.state.computer)
        return [...this.state.batchComputersInfo, informationCurrentComputer];
    }

    cleanSerialNumberAssignedAndLocation(computer){
        let newComputer = {...computer}
        newComputer.serial_number = ''
        newComputer.pine_assigned_name = ''
        newComputer.location = ''
        return newComputer
    }
    extractBatchInfo(computer) {
        return {
            serial_number: computer.serial_number,
            pine_assigned_name: computer.pine_assigned_name,
            location: computer.location
        };
    }

    handleSubmit = () => {
        const computers = this.createComputers()
        HardwareAppAPI.addComputers(computers)
            .then(() => {
                this.props.updateComputers()
                this.props.openConfirmMessage(true, "Computadoras creadas correctamente.")
                this.setState({
                    ...this.initialState,
                    pine_assigned_name: '',
                    pines: this.state.pines,
                })})
            .catch(({status, message}) => {
                const messageResponse = status >= 500 ? MESSAGE_SERVER_ERROR : message
                this.props.openConfirmMessage(true, messageResponse)
            })
    }

    createComputers() {
        return this.detailsAllComputers().map(batchComputerInfo =>
            ({
                ...this.state.computer,
                ...this.extractBatchInfo(batchComputerInfo)
            })
        );
    }
    redirectToLogin = error => {
        this.props.history.push(`/?error=${error.message}`)
    };



    createFieldForForm(type, keyOfState, label, placeHolder, value, isRequired, min) {
        return <React.Fragment>
            <Form.Input label={label} required={isRequired} type={type} min={min}
                        onChange={(event) => this.handleComputerChange(keyOfState, event.target.value)}
                        placeholder={placeHolder}
                        value={value}/>
            <SecondaryTrashButton onClick={() => this.handleComputerChange(keyOfState, "")}/>
        </React.Fragment>
    }

    createFormDropdownAutocomplete(options, value, updateState, callbackKeyOfState, label, allowAdditions, whenAddElement) {
        return (
            <React.Fragment>
                <DropdownAutocomplete options={options}
                                      value={value}
                                      whenSelect={updateState}
                                      whenAddingElement={whenAddElement}
                                      placeHolder={label}
                                      keyOfState={callbackKeyOfState}
                                      allowAdditions={allowAdditions}/>
                <SecondaryTrashButton onClick={() => updateState("")}/>
            </React.Fragment>
        )
    }

    componentDidMount() {
        HardwareAppAPI.getPines()
            .then((pines => {
                this.setState({pines})
            }))
            .catch(this.redirectToLogin)
    }

    pinesToPineName = (pines) => pines.map(pine => stringToDropdownOption(pine.name));


    addPine = (assignment_name) => {
        const pine = {name: assignment_name};
        this.setState({pines: this.state.pines.concat(pine)})
    }

    createFromLocation(location) {
        return this.createFieldForForm('text', 'location', 'Ubicación', 'Ubicación', location, false)
    }

    addElementToStateLists = (keyOfState, value) => {
        this.setState({[keyOfState]: [...this.state[keyOfState], value]})
    };
}

export default withRouter(AddComputer);
