import {Dropdown, Menu, Responsive} from "semantic-ui-react";
import React from "react";

const HeaderMenu = (props) =>{
    return <>
        <Responsive as={Menu.Menu} minWidth={700}>
            {props.menuOptions.map( (menuItem) =>
                <Menu.Item key = {menuItem.id} name= {menuItem.name} onClick= {menuItem.redirection} /> )}
        </Responsive>
        <Responsive style={{display: 'flex'}} maxWidth={700}>
            <Dropdown item text={"Inventario"} icon="desktop" simple style={{width: '25%'}}>
                <Dropdown.Menu>
                    {props.menuOptions.map((menuItem)=>
                        <Dropdown.Item
                            key = {menuItem.id}
                            text={menuItem.name}
                            onClick={menuItem.redirection}
                        />)}
                </Dropdown.Menu>
            </Dropdown>
        </Responsive>
    </>
}
export default HeaderMenu;