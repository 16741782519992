import React, {Component} from 'react';
import {Button, Grid, Image, Message, Segment} from "semantic-ui-react";
import pino from "../resources/pines-logo.png";
import {withRouter} from "react-router-dom";
import routes from "../Routes";
import * as queryString from "query-string";
import "../Css/Login.css"
import {authService} from "../services/AuthService";

export class Login extends Component {
    componentDidMount() {
        const locationState = this.props.history.location.state;
        const pageFrom = locationState && locationState.from;

        if(authService.isLogged()){
            this.props.history.push(pageFrom || routes.computers)
        }
        if(pageFrom)
            localStorage.setItem("URL_TO_REDIRECT", pageFrom.pathname)
    }

    render() {
        return <Grid centered divided relaxed='very' stackable>
                <Segment size='large' placeholder className="PlaceHolder" color="green">
                    {this.renderError()}
                    <Grid.Row>
                        <Grid.Column>
                            <p>Entrá usando tu usuario de:
                            </p>
                            <BackofficeButton/>
                        </Grid.Column>
                    </Grid.Row>
                </Segment>
            </Grid>
    }

    renderError = () => {
        if(this.error()){
           return  <Grid.Row>
               <Message negative>
                   <Message.Header>{this.error()}</Message.Header>
               </Message>
           </Grid.Row>
        }
    }

    error(){
        return queryString.parse(this.props.location.search).error;
    }

    redirectToBackoffice() {
        window.location.href = `${process.env.REACT_APP_BACKEND_URL || ''}/auth/backoffice/redirect`;
    }

}

const BackofficeButton = () => {
    if (process.env.NODE_ENV === 'development') {
        return <form action={`${process.env.REACT_APP_BACKEND_URL}/auth/backoffice`} method="get">
            <Button positive>
                <div className='center'>
                    <Image size='mini' src={pino} className='right-margen'/>
                    <p>Backoffice</p>
                </div>
            </Button>
        </form>
    }

    return <form action="/auth/backoffice" method="post">
        <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector('[name=csrf-token]').content}
        />
        <Button positive>
            <div className='center'>
                <Image size='mini' src={pino} className='right-margen'/>
                <p>Backoffice</p>
            </div>
        </Button>
    </form>
}

export default withRouter(Login)
