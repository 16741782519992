import {Button, Checkbox, Grid, Popup} from "semantic-ui-react";
import React, {Component} from 'react'

export default class ColumnToggle extends Component {
    toggles = (toggleList,column) => (column.toggeable)? [...toggleList, this.toggleFor(column)] : toggleList;

    toggleFor(column) {
        return <Grid columns={2} centered divided>
            <Grid.Row>
                <Grid.Column textAlign='center'><p>{column.title}</p></Grid.Column>
                <Grid.Column textAlign='center'> <Checkbox slider checked={column.shouldShow}
                                        onChange={this.props.onToggle(column)}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    }

    render() {
        return <Popup position={'bottom left'} trigger={<Button icon={"eye"}/>} on='click'>
            <Grid columns={1}>
                {this.props.columns.reduce(this.toggles, [])}
            </Grid>
        </Popup>
    }

}