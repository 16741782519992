import React from 'react'
import {Accordion, Button, Container, Divider, Form, Icon, Segment} from "semantic-ui-react";
import {COMPUTER_STATUS, stringsToDropdownOption} from "../services/ComputerStatus";
import FilterDropdown from "./FilterDropdown";
import HardwareAppAPI from "../services/HardwareAppAPI";
import DatePicker from "./DatePicker";
import TrashButton from "./TrashButton";
import {withRouter} from "react-router-dom";

class ComputersFilterForm extends React.PureComponent {

    toggleShowPanel = () => this.setState({show_panel: !this.state.show_panel});

    constructor(props) {
        super(props);
        this.state = this.getFiltersFromURI();
        this.state.brands = this.props.computersBrands;
        this.state.models = this.props.computersModels;
    }

    getFiltersFromURI() {
        const searchParams = new URLSearchParams(this.props.history.location.search)
        return {
            show_panel: searchParams.get('show_panel'),
            brand: searchParams.get('brand'),
            with_model: searchParams.get('with_model'),
            status: searchParams.get('status'),
            from_date: searchParams.get('from_date'),
            until_date: searchParams.get('until_date'),
            assigned_pine_id: searchParams.get('assigned_pine_id'),
            serial_number: searchParams.get('serial_number'),
            location: searchParams.get('location')
        }
    };

    render() {
        return (
            <React.Fragment>
                <Accordion styled fluid>
                    <Accordion.Title active={this.state.show_panel}
                                     onClick={this.toggleShowPanel}>
                        <Icon name='dropdown'/>
                        Filtrar por
                    </Accordion.Title>
                    <Accordion.Content active={this.state.show_panel}>
                        <Segment raised>
                            <Divider horizontal>Filtros completos</Divider>

                            <Form>
                                <Form.Group widths='equal'>
                                    {this.createFilterDropdown('brand', 'Marca', this.state.brand, this.state.brands, this.handleChangeBrand)}
                                    {this.createFilterDropdown('with_model', 'Modelo', this.state.with_model, this.state.models, this.handleChangeModel)}
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    {this.createFilterDropdown('status', 'Estado', this.state.status,COMPUTER_STATUS)}
                                    <FilterDropdown
                                        label='Asignacion'
                                        value={this.findSelectedPine()}
                                        options={this.props.pines}
                                        whenSelect={(pine) => this.setState({assigned_pine_id: this.getPineId(pine) })}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    {this.createFilterDropdown('location', 'Ubicación', this.state.location, this.props.locations)}
                                    <Container></Container>
                                    {/* Container vacío para que el input de la ubicación ocupe el 50% del container padre */}
                                </Form.Group>
                                <Divider horizontal>Filtrar por fecha de compra</Divider>
                                <Form.Group widths='equal' className='fields-row-form-container'>
                                    {this.createFilterDatePicker('from_date', 'Desde', this.state.from_date)}
                                    {this.createFilterDatePicker('until_date', 'Hasta', this.state.until_date)}
                                </Form.Group>
                                <Form.Group widths={3}>
                                    <Container>
                                        <TrashButton onCleanField={() => this.cleanFields()}/>
                                        <Button onClick={() => this.updateFiltersUrlFromState()} floated='right'
                                                color='green'>Filtrar</Button>
                                    </Container>
                                </Form.Group>
                            </Form>
                        </Segment>
                    </Accordion.Content>
                </Accordion>
                <Segment>
                    <Form>
                        <Form.Group widths='equal'>
                            {this.createFilterDropdown('serial_number', 'Numero de Serie', this.state.serial_number, this.props.serialNumbers)}
                            <Container>
                                <div className={'right-down-div'}>
                                    <Button floated='right' color='green' onClick={() => this.searchForSerialNumber()}>Busqueda
                                        rápida</Button>
                                </div>
                            </Container>
                        </Form.Group>
                    </Form>
                </Segment>
            </React.Fragment>
        )
    }

    getPineId(pine) {
        return pine ? pine.id : null;
    }

    findSelectedPine() {
        let pines = this.props.pines;
        let filter = pines.filter(p => p.value.id == this.state.assigned_pine_id);
        return filter.length ? filter[0].value : null;
    }

    createFilterDropdown(keyOfState, placeHolder, value, options, handleChange = this.handleChange) {
        return (
            <FilterDropdown label={placeHolder}
                            value={value}
                            options={stringsToDropdownOption(options)}
                            whenSelect={(value) => handleChange(keyOfState, value)}
            />
        )
    }

    createFilterDatePicker(keyOfState, placeHolder, value) {
        return (<DatePicker label={placeHolder}
                            placeholder={placeHolder}
                            value={value}
                            onChange={(value) => this.handleChange(keyOfState, value)}/>
        )
    }

    handleChangeBrand = (keyOfState, value) => {
        const models = this.extractModelsWithBrand(value)
        const with_model = models.includes(this.state.with_model) ? this.state.with_model : ""
        this.setState({models, with_model})
        this.handleChange(keyOfState, value)
    }
    handleChangeModel = (keyOfState, value) => {
        const brand = value === null ? this.state.brand : this.extractBrandWithModel(value)
        const models = this.extractModelsWithBrand(brand)
        this.setState({models, brand})
        this.handleChange(keyOfState, value)
    }

    extractModelsWithBrand(brand) {
        return brand === null ? this.props.computersModels : this.props.brandsWithModels[brand];
    }

    extractBrandWithModel(value) {
        return Object.keys(this.props.brandsWithModels).find(key => this.props.brandsWithModels[key].includes(value));
    }
    handleChange = (keyOfState, value, callback) => {
        this.setState({[keyOfState]: value}, () =>{
            if(value === null)
                this.updateFiltersUrlFromState()
        })
    }

    updateFiltersUrlFromState() {
        this.props.history.replace('/computadoras?' + HardwareAppAPI.formatQueryParamsForFilter(this.state))
        this.props.filter();
    }

    cleanFields() {
        this.props.history.replace('/computadoras?show_panel=true');
        this.setState(this.getFiltersFromURI())
        this.props.filter();
    }

    searchForSerialNumber() {
        this.props.history.replace('/computadoras?' + HardwareAppAPI.formatQueryParamsForFilter({serial_number: this.state.serial_number}));
        this.props.filter();
    }

    cleanSerialNumber() {
        this.handleChange('serial_number', '')
    }
}

export default withRouter(ComputersFilterForm);
