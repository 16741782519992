import React from 'react'
import {DateInput} from "semantic-ui-calendar-react";
import "../Css/Buttons.css"
import {SecondaryTrashButton} from "./SecondaryTrashButton";

const DatePicker = ({placeHolder, value, onChange,required,an_specific_width}) => {
    return <React.Fragment>
        <DateInput closable={true}
                   label={placeHolder}
                   placeholder={placeHolder}
                   value={value}
                   required = {required}
                   width = {an_specific_width}
                   animation={'none'}
                   pickerStyle={{display:'block'}}
                   onChange={(event, data) => onChange(data.value)}/>
        <SecondaryTrashButton onClick={() => onChange(null, {value: null})}/>
    </React.Fragment>
}

export default DatePicker;
