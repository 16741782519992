import {Table} from "semantic-ui-react";
import React from "react";
import TableRow from "./TableRow";

const defaultEncryptionValue = 'No';
const defaultValue = '-';

const DeviceDetailsTable = (props) => {
    return  <Table  basic="very" celled>
        <Table.Body>
            <TableRow header="Marca" value={props.computer.model.brand || defaultValue}/>
            <TableRow header="Modelo" value={props.computer.model.name || defaultValue}/>
            <TableRow header="Fecha de compra" value={displayDateOfPurchase(props.computer) || defaultValue}/>
            <TableRow header="Placa de red" value={props.computer.network_card || defaultValue}/>
            <TableRow header="Número de serie" value={props.computer.serial_number || defaultValue}/>
            <TableRow header="Encriptada"
                      value={props.computer.encryption || defaultEncryptionValue}/>
            <TableRow header="Seguro" value={props.computer.insurance_status || defaultValue}/>
            <TableRow header="Estado" value={props.computer.status || defaultValue}/>
            <TableRow header="Ubicación" value={props.computer.location || defaultValue}/>
            <TableRow header="Asignada a"
                      value={props.computer.assignment && props.computer.assignment.name || defaultValue}/>
            <TableRow header="Dueño" value={props.computer.owner || defaultValue}/>
            <TableRow header="Costo" value={props.computer.cost ? "$" + props.computer.cost : defaultValue}/>
            <TableRow header="Notas" value={props.computer.notes || defaultValue}/>

        </Table.Body>
    </Table>
}

function displayDateOfPurchase(computer) {
    const default_purchase_date = '2000-01-01';
    return computer.date_of_purchase === default_purchase_date ? '' : computer.date_of_purchase;
}
export default DeviceDetailsTable;