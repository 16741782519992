import {authService} from "./AuthService";


export function getUserRole() {
    if(!authService.isLogged())
        return noLogged;
    const isAdmin =  authService.actualUser().is_admin;
    return isAdmin ? admin : pino;
}

const admin = {

    acceptVisitor: (visitor) => {
        return visitor.visitAdmin()
    }

};

const pino = {

    acceptVisitor: (visitor) => {
        return visitor.visitPino()
    }

};

const noLogged = {

    acceptVisitor: (visitor) => {
        return visitor.visitNoLogged()
    }

};

