import React, {Component} from 'react'
import {Confirm, Container, Header, Pagination, Segment} from "semantic-ui-react";
import HardwareAppAPI from "../services/HardwareAppAPI";
import AddComputer from "./AddComputer";
import {withRouter} from "react-router-dom";
import ComputerTable from "./ComputerTable";
import RenderForUser from "./RenderForUser";
import ComputersFilterForm from "./ComputersFilterForm";
import Loading from "./Loading";

const unique = (data) => [...new Set(data)];

const POSSIBLE_LOCATIONS = ["Oficina", "Fuera de la oficina"];

class ComputerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            computers: [],
            activePage: 1,
            brands: [],
            models: [],
            network_cards: [],
            pines: [],
            serialNumbers: [],
            openMessageResponse: false,
            messageResponse: "",
            locations: POSSIBLE_LOCATIONS,
            brandsWithModels: {}
        };
    }

    componentDidMount() {
        HardwareAppAPI.getProcessPines()
            .then((pines) => {
                this.setState({pines})
            })
            .catch(() => this.props.history.push(`/`));
    };

    render() {
        return <Loading actionPromise={this.updateComputers}
                        whenLoaded={() => {
                            return <RenderForUser user={this.props.user}
                                                  whenAdmin={this.whenAdmin}
                                                  whenPino={this.whenPino}/>
                        }}/>
    }

    messageResponseAction() {
        return <Confirm open={this.state.openMessageResponse}
                        header={this.state.messageResponse}
                        content={null}
                        cancelButton={null}
                        confirmButton={"Ok"}
                        onConfirm={() => this.openConfirmMessage(false, "")}
        />;
    }
    openConfirmMessage = (open, message) => {
        this.setState({
            openMessageResponse: open,
            messageResponse: message
        })
    }


    whenAdmin = () => {
        return <Container>
            {this.showPanelAddComputer()}
            {this.showFilters()}
            {this.computersInformation()}
            {this.messageResponseAction()}
        </Container>
    };

    whenPino = () => {
        return <Container>
            {this.showFilters()}
            {this.computersInformation()}
        </Container>
    };


    renderComputerTable = () => {
        const pagination = <Pagination boundaryRange={0}
                                       ellipsisItem={null}
                                       siblingRange={1}
                                       activePage={this.state.activePage}
                                       onPageChange={this.changePage}
                                       totalPages={this.state.totalPages}/>;
        return <ComputerTable
            filter={this.getFiltersFromURI()}
            refreshComputer={this.refreshComputer}
            pines={this.state.pines}
            brands={extractBrands(this.state.brandsWithModels)}
            models={extractModels(this.state.brandsWithModels)}
            network_cards={this.state.network_cards}
            computers={this.state.computers}
            pagination={pagination}
            activePage={this.state.activePage}
            searchNewComputers={this.updateComputers}
        />
    };

    getFiltersFromURI() {
        let filters = {};
        let search = decodeURI(this.props.history.location.search);

        search.slice(1).split('&').forEach(x => {
            let [k, v] = x.split('=');
            filters[k] = v
        });
        return filters;
    }


    computersInformation = () => (this.state.computers.length > 0) ?
        this.renderComputerTable()
        :
        <Segment className={'SegmentColor'}>
            <Header as={"h1"} textAlign={"center"}>No hay computadoras para los filtros aplicados</Header>
        </Segment>;

    showFilters = () => {
        return <ComputersFilterForm computersBrands={extractBrands(this.state.brandsWithModels)}
                                    computersModels={extractModels(this.state.brandsWithModels)}
                                    pines={this.state.pines}
                                    serialNumbers={this.state.serialNumbers}
                                    locations={this.state.locations}
                                    filter={() => this.updateComputers()}
                                    brandsWithModels={this.state.brandsWithModels}/>
    };

    refreshComputer = (computerToRefresh) => {
        const computers = this.state.computers;
        const computerIndex = computers.findIndex((pc) => pc.id === computerToRefresh.id);
        computers[computerIndex] = computerToRefresh;

        this.setState({computers})
    };

    showPanelAddComputer() {
        return <AddComputer computersBrands={extractBrands(this.state.brandsWithModels)}
                            computersNetworkCards={this.state.network_cards}
                            updateComputers={this.updateComputers}
                            openConfirmMessage = {this.openConfirmMessage}
                            brandsWithModels = {this.state.brandsWithModels}
        />
    }

    updateComputers = (order) => {
        const defaultOrder = {column: 'date_of_purchase', order: 'descending'};
        const actualOrder = order || defaultOrder;
        return HardwareAppAPI.getComputersInfo(this.getFiltersFromURI(), this.state.activePage, actualOrder)
            .then(({computers, total_pages, network_cards, serialNumbers, brandsWithModels}) => {
                this.setState({computers, network_cards, totalPages: total_pages, serialNumbers, brandsWithModels})
            })
    };

    changePage = (e, {activePage}) => {
        this.setState({activePage}, this.updateComputers);
    }

}
export function extractModels(brandsWithModels) {
    return [...new Set([].concat(...Object.values(brandsWithModels)))];
}

export function extractBrands(brandsWithModels) {
    return Object.keys(brandsWithModels);
}


export default withRouter(ComputerList)
