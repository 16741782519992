import Chart from "react-apexcharts";
import React from "react";
import {A_LA_VENTA, ASIGNADA, BACK_UP, DADA_DE_BAJA, EN_REPARACION, POR_REPARAR} from "../services/ComputerStatus";

export function ComputersSummaryChart({computersByState}) {
    return <div id="chart" className='summary-chart'>
        <Chart options={{
            labels: ["Backup", "Reparación", "Asignadas", "Dadas de Baja", "A la Venta", "Por Reparar"],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: "bottom"
                    }
                }
            }],
            colors: ["#F7931E", "#352B26", "#68BD44", "#FF0000", "#00AAE4", "#0000FF"]
        }}
               series={[computersByState[BACK_UP].length, computersByState[EN_REPARACION].length, computersByState[ASIGNADA].length, computersByState[DADA_DE_BAJA].length, computersByState[A_LA_VENTA].length, computersByState[POR_REPARAR].length]}
               type="pie" width="380"/>
    </div>;
}