import React from 'react'
import {Button} from "semantic-ui-react";
import {PineRotations} from "./PineRotations";

const ButtonsConditional = (props) => {
    if (props.isEditing) {
        return <React.Fragment>
            <Button color='green' circular icon='save' onClick={props.onSave}/>
            <Button color='red' circular icon='delete' onClick={props.onCancel}/>
        </React.Fragment>
    } else {
        return <React.Fragment>
            <Button color='green' circular icon='edit' onClick={props.onEdition}/>
            <Button color='red' circular icon='delete' onClick={props.onDelete}/>
            <PineRotations pine={props.pine}/>
        </React.Fragment>

    }
};

export default ButtonsConditional
