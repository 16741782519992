import React, {Component} from 'react'
import {Container, Divider, Segment, Table} from "semantic-ui-react";
import {Link, withRouter} from "react-router-dom";
import routes from "../Routes";
import HardwareAppAPI from "../services/HardwareAppAPI";
import Loading from "./Loading";
import moment from "moment";
import '../Css/Login.css'
import '../Css/Rotation.css'
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";

class RotationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rotations: []
        }
    }

    loadRotations = () => {
        return HardwareAppAPI.getPinesByRotations()
            .then((rotations) => this.setState({rotations}))
    };

    render() {
        return <Container>
            <Segment white compact>
                <div>
                    <h1>
                        Rotaciones
                    </h1>
                    <Divider/>
                    <HeaderSubHeader>La formula aplicada da prioridad a los años de antiguedad del dispositivo y en
                        segunda instancia de antiguedad del Pine. Las rotaciones se encuentran ordenadas del más al
                        menos prioritario. </HeaderSubHeader>
                    <br/>
                </div>

                <Loading actionPromise={this.loadRotations}
                         whenLoaded={this.renderRotationsTable}/>
            </Segment>
        </Container>
    }

    redirectToRotationParams = () => {
        this.props.history.push(routes.rotationsParams)
    };

    renderRotationsTable = () => {
        return <div className={'center'}>
            <Table celled collapsing>
                <Table.Header>
                    <Table.Row>
                        {this.renderHeaders()}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.generateListRows()}
                </Table.Body>
            </Table>
        </div>
    }

    renderHeaders() {
        return <React.Fragment>
            {this.columns.map(aColumn => <Table.HeaderCell>{aColumn.name}</Table.HeaderCell>)}
        </React.Fragment>;
    }

    generateListRows() {
        return this.state.rotations.map((rotation) => this.generatePineRow(rotation));
    };

    generatePineRow(rotation) {
        return <Table.Row key={rotation}>
            {this.columns.map(aColumn => <Table.Cell width={2}>{aColumn.onRowRender(rotation)}</Table.Cell>)}
        </Table.Row>;
    }

    columns = [
        {
            name: 'Pino', onRowRender: (rotation) => <h5>{rotation.pine_name}</h5>,
        },
        {
            name: 'Computadora', onRowRender: (rotation) => this.renderComputerInfo(rotation),
        },
        {
            name: 'Antigüedad del Dispositivo (en dias)', onRowRender: (rotation) => <h5>{rotation.device_age}</h5>
        },
        {
            name: 'Antigüedad del Pino (en dias)', onRowRender: (rotation) => <h5>{rotation.labor_seniority}</h5>
        }
    ];

    renderComputerInfo(rotation) {
        const computer = rotation.device;
        const dateOfPurchase = moment(computer.date_of_purchase);

        return <React.Fragment>
            <Link to={`/dispositivo/${computer.id}`}>
                {computer.model.brand + " " + computer.model.name}
            </Link>
        </React.Fragment>
    }

}

export default withRouter(RotationList)
