import React, {Component} from 'react'
import { Dropdown } from 'semantic-ui-react'
import HardwareAppAPI from '../services/HardwareAppAPI'

class InsuranceDropdown extends Component {

    constructor(props) {
        super(props)
        this.state = {  }
    }

    render() {
        return (
            <Dropdown value={this.props.value} selection
                      options={HardwareAppAPI.getStatusesOptions()}
                      onChange={(event, data) => this.props.onChange(data.value)}/>
        )
    }
}

export default InsuranceDropdown;