import {Button, Divider, Segment, Table} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import React, {Component} from 'react'
import moment from "moment";
import ComputerStatusDropdown from "./ComputerStatusDropdown";
import AssignDropdown from "./AssignDropdown";
import HardwareAppAPI from "../services/HardwareAppAPI";
import InsuranceDropdown from "./InsuranceDropdown";
import '../table-stylesheet.css';
import RenderForUser from "./RenderForUser";
import { A_LA_VENTA, ASIGNADA, BACK_UP, DADA_DE_BAJA, EN_REPARACION, POR_REPARAR } from "../services/ComputerStatus";
import ColumnToggle from "./ColumnToggle";
import {FilterText} from "./FilterText";
import {NotesIcon} from "./NotesIcon";

class ComputerTable extends Component {
    state = {
        columns: [
            {
                title: 'Nº',
                toggeable: false,
                shouldShow: true,
                body: (aComputer) => <Table.Cell width={0.2}>{this.getIndexForComputer(aComputer)}</Table.Cell>,
                sortable: false
            },
            {
                title: 'Marca',
                toggeable: false,
                shouldShow: true,
                body: (aComputer) => <Table.Cell width={1}>{aComputer.model.brand}</Table.Cell>,
                name: 'brand',
                sortable: true
            },
            {
                title: 'Modelo',
                toggeable: true,
                shouldShow: true,
                body: (aComputer) => <Table.Cell width={1}>{aComputer.model.name}</Table.Cell>,
                name: 'model',
                sortable: true
            },
            {
                title: 'Nº de Serie',
                toggeable: true,
                shouldShow: true,
                body: (aComputer) => <Table.Cell width={1}>{aComputer.serial_number}</Table.Cell>,
                name: 'serial_number',
                sortable: true
            },
            {
                title: 'Estado',
                toggeable: true,
                shouldShow: true,
                body: (aComputer) => <Table.Cell width={2}>{this.renderComputerStatus(aComputer)}</Table.Cell>,
                name: 'status',
                sortable: true
            },
            {
                title: 'Ubicación',
                toggeable: true,
                shouldShow: true,
                body: (aComputer) => <Table.Cell width={2}>{aComputer.location}</Table.Cell>,
                name: 'location',
                sortable: true
            },
            {
                title: 'Costo',
                toggeable: true,
                shouldShow: true,
                body: (aComputer) => <Table.Cell width={1}>{renderCost(aComputer.cost)}</Table.Cell>,
                name: 'cost',
                sortable: true
            },

            {
                title: 'Asignación',
                toggeable: true,
                shouldShow: true,
                body: (aComputer) => <Table.Cell width={2}>{this.renderAssignment(aComputer)}</Table.Cell>,
                name: 'pine_name',
                sortable: true
            },
            {
                title: 'Seguro',
                toggeable: true,
                shouldShow: false,
                body: (aComputer) => <Table.Cell>{this.getInsuranceDropDown(aComputer)}</Table.Cell>,
                name: 'insurance_status',
                sortable: true
            },
            {
                title: 'Dueño',
                toggeable: true,
                shouldShow: false,
                body: (aComputer) => <Table.Cell width={1}>{aComputer.owner}</Table.Cell>,
                name: 'owner',
                sortable: true
            },
            {
                title: 'Año',
                toggeable: true,
                shouldShow: true,
                body: (aComputer) => <Table.Cell>{getYearOfPurchaseOfComputer(aComputer)}</Table.Cell>,
                name: 'date_of_purchase',
                sortable: true
            },
            {
                title: '',
                toggeable: false,
                shouldShow: true,
                body: (aComputer) => <Table.Cell>{this.renderButtonsCell(aComputer)}</Table.Cell>,
                sortable: false
            }
        ]
    };

    toggle = (column) => (event, {checked}) => {
        const newColumns = [...this.state.columns];
        newColumns.find(aColumn => column.title === aColumn.title).shouldShow = checked;
        this.setState({columns: newColumns});
    };

    tableHeaders() {
        return this.state.columns.map(this.renderColumn)
    };

    renderColumn = aColumn => {
        let onClickEvent;
        if (aColumn.sortable)
            onClickEvent = this.handleSort(aColumn.name);
        return aColumn.shouldShow && <Table.HeaderCell onClick={onClickEvent}
                                                       sorted={this.state.column === aColumn.name ? this.state.direction : null}>{aColumn.title}</Table.HeaderCell>;
    };

    handleSort = (clickedColumn) => () => {
        const {column, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({direction: 'ascending', column: clickedColumn})
            this.props.searchNewComputers({column: clickedColumn, order: 'ascending'})
        } else {
            const newDirection = direction === 'ascending' ? 'descending' : 'ascending'
            this.setState({
                direction: newDirection,
                column: clickedColumn,
            })
            this.props.searchNewComputers({column: clickedColumn, order: newDirection})
        }

    }

    render() {
        return (
            <Segment className={'SegmentColor Overflow'} textAlign='left'>
                <h1>Computadoras</h1>
                {this.renderFilterSearchParams()}
                <Divider/>
                <div style={{'display': 'flex', 'justify-content': 'space-between'}}>
                    <ColumnToggle columns={this.state.columns} onToggle={this.toggle}/>
                    {this.props.pagination}
                </div>

                <Table compact size={'small'} sortable celled>
                    <Table.Header>
                        <Table.Row>
                            {this.tableHeaders()}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.generateListRows()}
                    </Table.Body>
                </Table>
                <div style={{'display': 'flex', 'justify-content': 'flex-end'}}>
                    {this.props.pagination}
                </div>
            </Segment>
        );
    }

    generateComputerRow(computer) {
        return <Table.Row className={this.getRowColor(computer)} key={computer.id}>
            {this.state.columns.map(aColumn => aColumn.shouldShow && aColumn.body(computer))}
        </Table.Row>;
    };

    getRowColor(computer) {
        if (computer.status === EN_REPARACION || computer.status === POR_REPARAR) {
            return 'BrokenComputerRow'
        }
        if (computer.status === BACK_UP || computer.status === A_LA_VENTA) {
            return 'WarningRow'
        }
        if (computer.status === ASIGNADA || computer.status === DADA_DE_BAJA) {
            return 'NormalRow'
        }
    };

    getInsuranceDropDown(computer) {
        return <RenderForUser user={this.props.user}
                              whenAdmin={() => {
                                  return <InsuranceDropdown value={computer.insurance_status}
                                                            onChange={(newInsuranceStatus) => HardwareAppAPI.changeInsuranceStatus(computer, newInsuranceStatus)
                                                                .then(computer => this.props.refreshComputer(computer))}/>
                              }}
                              whenPino={() => {
                                  return computer.insurance_status
                              }}/>
    };

    renderButtonsCell = (computer) => {
        return <Table.Cell collapsing>
            <Button onClick={()=>this.redirectToShowDevice(computer)} circular icon='eye' color='green'/>
            {
                <RenderForUser user={this.props.user}
                               whenAdmin={() => {
                                   return (
                                       <>
                                           <Button circular icon='edit'
                                                   onClick={() => this.redirectToEdition(computer)}/>
                                           {computer.notes ?
                                               <NotesIcon note={computer.notes}/> : <></>}
                                       </>
                                   )
                               }}
                               whenPino={() => {
                                   return null
                               }}
                />
            }
        </Table.Cell>
    };

    renderComputerStatus(computer) {
        return <RenderForUser user={this.props.user}
                  whenAdmin={() => {
                      return <ComputerStatusDropdown onChange={(newStatus) => this.updateComputerStatus(computer, newStatus)} computer={computer}/>
                  }}
                  whenPino={() => {
                      return computer.status
                  }}/>
    };

    updateComputerStatus(computer, newStatus) {
        return HardwareAppAPI.changeComputerState(computer, newStatus)
            .then(computer => { this.props.refreshComputer(computer) })
            .catch(({status, message}) => {
                if (status >= 500) {
                    this.cancelEdition('Hubo un problema en el servidor al querer actualizar la computadora.')
                } else {
                    alert(message)
                }
            })
    }

    renderAssignment(computer) {
        function getAssignment(computer) {
            return (!!computer.assignment) ? computer.assignment.name : ''
        }

        return <RenderForUser user={this.props.user}
                              whenAdmin={() => {
                                  return <AssignDropdown value={computer.assignment && computer.assignment.name}
                                                         onChange={(newAssignment) => HardwareAppAPI.changeAssignment(computer, newAssignment)
                                                             .then(computer => {
                                                                 this.props.refreshComputer(computer)
                                                             })
                                                             .catch(this.redirectToLogin)}
                                                         pinos={this.props.pines}
                                                         allowAdditions={false}
                                                         hasComputerAssigned={this.hasComputerAssigned}/>
                              }}
                              whenPino={() => {
                                  return <div>{getAssignment(computer)}</div>
                              }}/>
    };

    redirectToLogin = error => {
        this.props.history.push(`/?error=${error.message}`)
    };
    hasComputerAssigned = (pino) => {
        return pino !== null && this.props.computers.filter(c => c.assignment && c.assignment.id === pino.id).length > 0
    };

    generateListRows() {
        return this.props.computers.map((computer) => this.generateComputerRow(computer));
    };

    redirectToEdition = (computer) => {
        this.props.history.push(`/edit/${computer.id}`)

    };
    redirectToShowDevice = (computer) => {
        this.props.history.push(`/dispositivo/${computer.id}`)
    };

    renderFilterSearchParams() {
        return <FilterText filter={this.props.filter}/>
    };

    getIndexForComputer(aComputer) {
        const computerIndexInPagination = this.props.computers.findIndex((computer) => aComputer.id === computer.id) + 1;
        const pageOffset = (this.props.activePage - 1) * PAGESIZE;
        return computerIndexInPagination + pageOffset
    };
}

export function getYearOfPurchaseOfComputer(computer) {
    const default_year = 2000;
    return moment(computer.date_of_purchase).year() === default_year ? '' : moment(computer.date_of_purchase).year();
}

export function renderCost(cost) {
    return cost ? '$' + cost : '';
}

const PAGESIZE = 20;

export default withRouter(ComputerTable)
