import React from 'react'
import {Button, Confirm, Dropdown, Icon, Image, Popup} from 'semantic-ui-react'
import brokenHeart from '../resources/broken_heart.png'

class AssignDropdown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {open: false, loading: false}
    }

    render() {
        let {pinos, value} = this.props;
        return (
            <React.Fragment>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Dropdown placeholder={'Seleccioná un pino'}
                              options={pinos}
                              value={value}
                              text={value}
                              onChange={(even, data) => this.handleChange(data.value)}
                              selection
                              lazyLoad
                              search
                              onAddItem={(event, data) => this.onAddPino(data.value)}
                              allowAdditions={this.props.allowAdditions}
                              disabled={this.state.loading}
                              loading={this.state.loading}/>
                    {this.renderRemoveButton(value)}
                </div>

                <Confirm open={this.state.open}
                         content={`El pino ${this.state.selected && this.state.selected.name} ya tiene PC asignada. ¿Está seguro de asignarle otra computadora?`}
                         onConfirm={() => {
                             this.changeAssignment(this.state.selected);
                         }}
                         onCancel={() => {
                             this.setState({open: false})
                         }}
                         confirmButton={'Sí'}
                         cancelButton={'No'}/>

            </React.Fragment>
        )
    }

    changeAssignment(newAssignment) {
        this.props.onChange(newAssignment);
        this.setState({open: false, loading: true})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value)
            this.setState({loading: false})

    }

    handleChange = (newValue) => {
        if (this.props.hasComputerAssigned(newValue)) {
            this.setState({open: true, selected: newValue})
        } else {
            this.changeAssignment(newValue)
        }
    }

    agregarHashConPino(value, pinos) {
        pinos.push({
            key: value,
            text: value,
            value: value
        });
        return pinos
    }

    renderRemoveButton(assignedPine) {
        if (!assignedPine)
            return null;

        return <Popup trigger={<Icon style={{ marginLeft: '10px', cursor: 'pointer', color:'#352B26'}}
                                     size='large' name='user cancel'
                                     onClick={() => this.changeAssignment('')}/>}
                      content={"Quitar asignación"}
                      inverted
                      basic
                      style={{backgroundColor: 'rgba(53,43,38,0.63)'}}
                      position='top center'
        />
    }

    onAddPino(pine) {
        this.props.onAddPine(pine);
        this.setState({loading: false})
    }
}

export default AssignDropdown
