import {Accordion, Container, Form, Icon, Segment, TextArea} from "semantic-ui-react";
import React, {useEffect, useState} from 'react';
import HardwareAppAPI from "../services/HardwareAppAPI";
import PinesDropdown from "./PinesDropdown";

const CreateDeviceOrderAcordion = (props) => {
    const [description, setDescription] = useState()

    const [notes, setNotes] = useState()

    const [pineId, setPineId] = useState()

    const [pines, setPines] = useState([])

    const pinesForDropdownComponent = (pines) => {
        return pines.map(pine => {
            return {key: pine.id, text: pine.name, value: pine.id};
        });
    }

    const handleSubmit = () => {
        if (!!description && !!pineId)
            HardwareAppAPI.addDeviceOrder({description: description, notes: notes, pine_id: pineId}).then(() => {
                props.history.go(0)
            })
    }

    useEffect(() => {
        HardwareAppAPI.getPines().then(pines => pinesForDropdownComponent(pines)).then(pines => setPines(pines))
    }, [])

    return <>
        <Accordion styled fluid>
            <Accordion.Title active={props.showPanel}
                             onClick={props.toggleShowPanel}>
                <Icon name='dropdown'/>
                Crear Pedido
            </Accordion.Title>
            <Accordion.Content active={props.showPanel}>
                <Segment raised>
                <Form>
                    <Form.Field required>
                        <label>Breve Descripcion</label>
                        <input required placeholder='Descripcion'
                               onChange={(event) => setDescription(event.target.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Notas</label>
                        <TextArea placeholder='Notas' style={{minHeight: 100}}
                                  onChange={(event) => setNotes(event.target.value)}/>
                    </Form.Field>
                    <PinesDropdown pineId={pineId} pines={pines} whenSelect={(event,data) => setPineId(data.value)}/>
                    <Container>
                        <div className={'right-down-div'}>
                            <Form.Button color='green' onClick={handleSubmit} type='submit'
                                         floated='right'>Agregar</Form.Button>
                        </div>
                    </Container>
                </Form>
                </Segment>
            </Accordion.Content>
        </Accordion>
    </>
}


export default CreateDeviceOrderAcordion;