const routes = {
    login: "/",
    computers: "/computadoras",
    computersPino: "/computadoras?userRole=pino",
    computersAdmin: "/computadoras?userRole=admin",
    showDevice: "/dispositivo/:id",
    deviceOrders: "/pedidos/",
    edit: "/edit/:id",
    token: "/token/:token",
    userlist: "/usuarios",
    rotations: "/rotaciones",
    pines: "/pines",
    rotationsParams: "/rotaciones/parametros",
    summary:'/informes',
    payrollReport:'/reporteNomina'
};

export default routes;