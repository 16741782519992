import React, {Component} from "react";
import {Container, Loader, Message} from "semantic-ui-react";

class Loading extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.props.actionPromise()
            .then(() => {this.setState({loading: false})})
            .catch((error) => {this.setState({error: true})})
    }
    showError = () => {
        return <Container>
            <div className='center'>
                <div className='a_compact_message'>
                    <Message
                        error
                        icon='delete'
                        header='Ocurrio un error'
                        content='Por favor, reintentalo mas tarde.'
                    />
                </div>
            </div>
        </Container>
    };

    render(){
        if(this.state.error){
            return this.showError()
        }
        if(this.state.loading){
            return <Loader/>
        } else {
            return this.props.whenLoaded()
        }
    }


}

export default Loading
