import {Grid, List} from "semantic-ui-react";
import React from "react";
import moment from "moment";

const EventDetailsList = (props)=> {
    return <>
        <h3>Eventos</h3>
        {renderEventList(props.events)}
    </>
}

const EventListItem = ({event}) => {
    return <List.Item>
        <List.Content>
            <List.Header className={"Event-dateAndTime-header"}>{displayEventDateAndTime(event.datetime)}</List.Header>
            <List.Description className={"Event-description"}>{event.description}</List.Description>
        </List.Content>
    </List.Item>
}

const renderEventList = (events) =>{
    return <>
    <List divided relaxed="very">
        {events.map(event => <EventListItem event={event} key={event.id}/>)}
    </List>
    </>

}
function displayEventDateAndTime(datetime) {
    const momentDate = moment(datetime);
    return `${momentDate.format("DD-MM-YYYY")} a las ${momentDate.format("HH:mm")}`
}



export default EventDetailsList;