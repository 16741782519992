import React from "react";
import {Grid} from "semantic-ui-react";

const ColumnWithLabel = ({label,children,childrens}) => {
    return <Grid.Row>
        <Grid.Column width={3}>
            <h3>{label}</h3>
        </Grid.Column>
        <Grid.Column width={4}>
            {children}
        </Grid.Column>
    </Grid.Row>

}

export default ColumnWithLabel;