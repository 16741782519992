import * as jwt_decode from 'jwt-decode';

export const authService = {

    STORAGE_KEY: 'Authorization',

    saveToken(token) {
        localStorage.setItem(this.STORAGE_KEY, token);
    },

    removeToken() {
        localStorage.removeItem(this.STORAGE_KEY);
    },
    actualUser() {
        try{
            return jwt_decode(this.getToken());
        }
        catch (e) {
            this.removeToken();
            return ''
        }
    },
    isAValidUser(){
        let user = this.actualUser();
        return user && user !== ''
    },
    isLogged() {
        return !!this.getToken() && this.isAValidUser() ;
    },
    isTheUserLogged(user){
      return this.actualUser().user_id === user.id;
    },

    getToken() {
        return localStorage.getItem(this.STORAGE_KEY);
    },
}
