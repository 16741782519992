import React, {Component} from 'react'
import {Container, Divider, Segment} from "semantic-ui-react";
import HardwareAppAPI from "../services/HardwareAppAPI";
import _ from 'lodash'
import Loading from "./Loading";
import AddPine from "./AddPine";
import PineTable from "./PineTable";

class PineList extends Component {

    constructor(props) {
        super(props);
        this.state = {pines: []};
    }

    loadPines = () => {
        return HardwareAppAPI.getPines()
            .then((pines => {
                this.setState({pines})
            }))
    };

    render() {
        return (
            <Container text>
                <Segment className={'SegmentColor'} textAlign='left'>
                    <h1>Pinos</h1>
                    <Divider/>
                    <AddPine onAddPine={this.addPineToState}/>
                    <Loading actionPromise={this.loadPines}
                             whenLoaded={this.renderPineTable}/>
                </Segment>
            </Container>

        );
    }

    renderPineTable = () => {
        return <PineTable
            pines={this.state.pines}
            savePine={this.savePine}
            deletePine={this.deletePine}
            isEditingThisPine={this.isEditingThisPine}
            sortPines={() => this.setState({pines: this.state.pines.sort((a, b) => b.computers.length - a.computers.length)})}
        />
    }

    isEditingThisPine(pine) {
        const pineToEdit = this.state.pineToEdit;
        return pineToEdit && pine.id === pineToEdit.id;
    }

    savePine = (pine) => {
        return HardwareAppAPI.updatePine(pine)
            .then(this.refreshEditedPine)
    }

    refreshEditedPine = (pineToRefresh) => {
        const pines = this.state.pines;
        const pineIndex = pines.findIndex((pine) => pineToRefresh.id === pine.id);
        pines[pineIndex] = pineToRefresh;
        this.setState({pines})
    };

    deletePine = (pine) => {
        HardwareAppAPI.deletePine(pine)
            .then(() => this.removePineFromState(pine))
    };

    removePineFromState = (pine) => {
        const pines = _.without(this.state.pines, pine);
        this.setState({pines})
    };

    addPineToState = (pine) => {
        const pines = [pine].concat(this.state.pines);
        this.setState({pines})
    }

}


export default PineList
