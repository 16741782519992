export const BACK_UP = "Back up";

export const A_LA_VENTA = "A la Venta";

export const POR_REPARAR= "Por Reparar";

export const DADA_DE_BAJA = "Dada de Baja";

export const EN_REPARACION= "En Reparación";

export const ASIGNADA = "Asignada";

export const COMPUTER_STATUS = [BACK_UP, DADA_DE_BAJA, EN_REPARACION, ASIGNADA, A_LA_VENTA, POR_REPARAR];

export const computerStatusOptionsForDropdown = () => COMPUTER_STATUS
    .map((status, index) => ({
        key: index,
        text: status,
        value: status,
    }));

export function stringsToDropdownOption(options){
    return options.map(stringToDropdownOption)
}
export function stringToDropdownOption(option) {
    return {
        key: option,
        text: option,
        value: option,
    }
}
