import React, {Component} from 'react'
import {Button, Container, Form, Icon, Segment, Table} from "semantic-ui-react";
import {DropdownAutocompleteWithoutLabel} from "./DropdownAutocomplete";
import HardwareAppAPI from "../services/HardwareAppAPI";
import _ from 'lodash'

class RotationParams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            deprecatedModels: [],
        }
    }

    componentDidMount() {
        HardwareAppAPI.getModels()
            .then(({models, deprecated_models}) => {
                this.setState({models, deprecatedModels: deprecated_models})
            })
    }

    modelToDropdownOption(models) {
        return models.map((model) => ({
            key: model.name,
            text: model.name,
            value: model,
        }))
    }

    render() {
        return <Container>
            <Segment size='mini' raised className={'SegmentColor'} textAlign='left'>
                <h1>Modelos deprecados</h1>
                {this.renderModelTable()}
            </Segment>
        </Container>;
    }

    renderModelTable() {
        return <Table size='small' >
            <Table.Header>
                <Table.Row>
                    {this.tableHeaders()}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {this.generateListRows()}
                {this.renderDeprecationForm()}
            </Table.Body>
        </Table>;
    }

    generateListRows() {
        return this.state.deprecatedModels.map((computer) => this.generateModelRow(computer));
    };

    tableHeaders() {
        return (<React.Fragment>
            <Table.HeaderCell><h5>Modelo</h5></Table.HeaderCell>
            <Table.HeaderCell><h5>Acciones</h5></Table.HeaderCell>
        </React.Fragment>)
    }

    generateModelRow(model) {
        return <Table.Row key={model}>
            {this.columns.map(aColumn => <Table.Cell width={2}>{aColumn.onRowRender(model)}</Table.Cell>)}
        </Table.Row>;
    }

    renderButtons = (model) => {
        return <Button color='orange' icon='close' content={'Cancelar deprecación'}  onClick={() => this.cancelDeprecation(model)} />
    };

    columns = [
        {
            name: 'Modelo', onRowRender: (model) => <h5>{model.name}</h5>,
        },
        {
            name: 'Acciones', onRowRender: this.renderButtons
        }
    ];


    renderDeprecationForm() {
        const models = this.modelToDropdownOption(this.state.models);
        return <Table.Row key={'depractedForm'}>
            <Table.Cell width={2}>
                <DropdownAutocompleteWithoutLabel  options={models}
                                      value={this.state.modelToDeprecate}
                                      whenSelect={(value) => this.setState({modelToDeprecate: value})}
                                      placeHolder='Modelo'
                                      keyOfState={'modelToDeprecate'}
                                      allowAdditions={false}/>
            </Table.Cell>
            <Table.Cell width={2}>
                <Button color={'green'} content={'Deprecar'} icon='add' disabled={!this.state.modelToDeprecate} onClick={() => this.handleSubmit()}/>
            </Table.Cell>
        </Table.Row>
    }

    handleSubmit() {
        const {modelToDeprecate, deprecatedModels} = this.state;
        const newDeprecatedModels = deprecatedModels.concat(modelToDeprecate);
        HardwareAppAPI.updateModel({name: modelToDeprecate.name, deprecated: true})
            .then( () => this.setState({deprecatedModels: _.uniq(newDeprecatedModels), modelToDeprecate: null}))
    }

    cancelDeprecation = (model) => {
        const deprecatedModels = this.state.deprecatedModels.filter(aModel => aModel !== model)
        HardwareAppAPI.updateModel({name: model.name, deprecated: false})
            .then( () => this.setState({deprecatedModels}))
    }
}

export default RotationParams
