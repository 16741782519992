import GlowingPineSpinner from 'glowing-pine-spinner';
import React from "react";

export const PineSpinner = (props) => (
        <GlowingPineSpinner
            size={props.size || 70}
            duration={0.5}
            strokeWidth={10}
            firstColor={"#a2ff1f"}
            secondColor={"#48ff00"}
            thirdColor={"#239e02"}
            glow={true}
        />
);
