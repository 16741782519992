import {List, Table} from "semantic-ui-react";
import React from "react";

const TableRow = ({header, value}) => {
    return (
        <Table.Row>
            <Table.Cell><b>{header}</b></Table.Cell>
            <Table.Cell>{value}</Table.Cell>
        </Table.Row>
    )
}


export default TableRow;