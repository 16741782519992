import React, {useEffect, useState} from 'react';
import {Container, Grid, Segment, Statistic} from "semantic-ui-react";
import {
    A_LA_VENTA,
    ASIGNADA,
    BACK_UP,
    COMPUTER_STATUS,
    EN_REPARACION,
    POR_REPARAR
} from "../services/ComputerStatus";
import HardwareAppAPI from "../services/HardwareAppAPI";
import '../summary.css'
import {ComputersSummaryChart} from "./ComputersSummaryChart";
import {Link} from "react-router-dom";

export const Summary = () => {
    const [computers, setComputers] = useState([]);
    useEffect(() => {
            HardwareAppAPI.getAllComputers()
                .then(computers => setComputers(computers))
        }
        , []);

    function getComputersByState(state) {
        return computers.filter(computer => computer.status === state)
    }

    function computersSeparatedByState() {
        let mapOfComputersSeparatedByState = {}
        COMPUTER_STATUS.forEach( computerState => mapOfComputersSeparatedByState[computerState] = getComputersByState(computerState))
        return mapOfComputersSeparatedByState
    }

    return (
        <Container>
            <Segment className='summary-segment'>
                <Grid celled>
                    <Grid.Row columns={2}>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                            <Grid>
                                <Grid.Row divided columns={2}>
                                    <Grid.Column>
                                        <ComputerStatistics value={computers.length} label='Total de computadoras'/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <ComputerStatistics extraFilters={`status=${ASIGNADA}`} value={getComputersByState(ASIGNADA).length} label='Asignadas'/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <ComputersSummaryChart computersByState={computersSeparatedByState()}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row divided columns={2}>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                            <Grid>
                                <Grid.Row divided columns={2}>
                                    <Grid.Column>
                                        <ComputerStatistics extraFilters={`status=${EN_REPARACION}`} value={getComputersByState(EN_REPARACION).length} label='En Reparación'/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <ComputerStatistics extraFilters={`status=${POR_REPARAR}`} value={getComputersByState(POR_REPARAR).length} label='Por Reparar'/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                            <Grid>
                                <Grid.Row divided columns={2}>
                                    <Grid.Column>
                                        <ComputerStatistics extraFilters={`status=${BACK_UP}`} value={getComputersByState(BACK_UP).length} label='En Backup'/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <ComputerStatistics extraFilters={`status=${A_LA_VENTA}`} value={getComputersByState(A_LA_VENTA).length} label='A la Venta'/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    )
};

function ComputerStatistics({value, label, extraFilters}) {
    return (
        <Link to={`/computadoras?${extraFilters}`}>
            <Statistic>
                <Statistic.Value>{value}</Statistic.Value>
                <Statistic.Label>{label}</Statistic.Label>
            </Statistic>
        </Link>
    );
}
