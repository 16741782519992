import {Icon, Popup} from "semantic-ui-react";
import React from "react";

export function NotesIcon({note}) {

    let maxChar = 80;

    function isLongNote(note) {
        return note.length > maxChar;
    }

    function shorthand(note) {
        return note.substring(0, maxChar) + '...';
    }

    return (
                <Popup style={{backgroundColor:'#F9A825', color: 'white'}}
                trigger={<Icon name='warning sign'
                                  size='large' color='orange'/>}
                   content={isLongNote(note) ? shorthand(note) : note}
                       basic
                       position='left center'
            />
    );

}


