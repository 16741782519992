import React from 'react'
import {Form} from "semantic-ui-react";
import "../Css/Buttons.css"
import {SecondaryTrashButton} from "./SecondaryTrashButton";


const FilterDropdown = ({label, value, options, whenSelect,selectOnBlur=false}) => (
    <React.Fragment>
        <Form.Dropdown
            placeholder={label}
            label={label}
            fluid
            search
            selectOnBlur={selectOnBlur}
            value={value}
            selection
            options={options}
            onChange={(event, data) => whenSelect(data.value)}
        />
        <SecondaryTrashButton onClick={() => whenSelect(null)}/>

    </React.Fragment>

);

export default FilterDropdown;
