import {Button, Input, Table} from "semantic-ui-react";
import ButtonsConditional from "./ButtonsConditional";
import React from 'react'
import '../Css/Buttons.css'
import {withRouter} from "react-router-dom";

class PineTable extends React.PureComponent {
    state = {
        pineToEdit: '',
        rotationsModalOpen: false
    }

    render() {
        return <Table compact size={'small'} sortable celled>
            <Table.Header>
                <Table.Row>
                    {this.tableHeaders()}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {this.generateListRows()}
            </Table.Body>
        </Table>


    }

    generateListRows() {
        return this.props.pines.map((rotation) => this.generatePineRow(rotation));
    };

    tableHeaders() {
        return <React.Fragment>
            {this.columns.map(aColumn => <Table.HeaderCell onClick={aColumn.onClick}>{aColumn.name}</Table.HeaderCell>)}
        </React.Fragment>;
    }

    generatePineRow(pine) {
        return <Table.Row key={pine.id}>
            {this.columns.map(aColumn => <Table.Cell width={aColumn.width}>{aColumn.onRowRender(pine)}</Table.Cell>)}
        </Table.Row>;
    }

    columns = [
        {
            name: 'Pino', onRowRender: (pine) => this.renderPine(pine), width: 1,
        },
        {
            name: 'PC Asignadas',
            onRowRender: (pine) => this.renderPineComputers(pine.computers),
            width: 1,
            onClick: () => this.props.sortPines()
        },
        {
            name: 'Acciones', onRowRender: (pine) => this.renderButtons(pine), width: 1,
        },
    ];

    renderButtons(pine) {
        return <ButtonsConditional
            onSave={this.savePine}
            onDelete={() => this.props.deletePine(pine)}
            onEdition={() => this.editPine(pine)}
            onCancel={this.cancelPine}
            isEditing={this.isEditingThisPine(pine)}
            pine={pine}
        />
    }

    savePine = () => {
        this.props.savePine(this.state.pineToEdit)
            .then(() => this.setState({pineToEdit: null}))
    }

    editPine = (pine) => {
        this.setState({pineToEdit: pine})
    };

    isEditingThisPine = (pine) => {
        const pineToEdit = this.state.pineToEdit;
        return pineToEdit && pine.id === pineToEdit.id;
    };

    cancelPine = () => {
        this.setState({pineToEdit: null})
    };

    renderPine = (pine) => {
        if (this.isEditingThisPine(pine))
            return <Input value={this.state.pineToEdit.name} onChange={this.changePineToEditName}/>
        else
            return <h5>{pine.name}</h5>
    };


    changePineToEditName = (event) => {
        const pineName = event.target.value
        this.setState(prevState => ({
            pineToEdit: {
                ...prevState.pineToEdit,
                name: pineName,
            }
        }))
    };

    renderPineComputers(computers) {
        return computers.map(c => <div className='margin-button'><Button
            onClick={() => this.props.history.push("/edit/" + c.id)}>{`${c.model.brand} ${c.model.name}`}</Button>
        </div>)
    }
}

export default withRouter(PineTable);
