import React from 'react'
import HardwareAppAPI from "../services/HardwareAppAPI";
import {Button, Table} from "semantic-ui-react";
import {authService} from "../services/AuthService";

class UserRow extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    render() {
        const {user} = this.props;
        return (
            <Table.Row>
                <Table.Cell>{user.nickname}</Table.Cell>
                <Table.Cell>{this.displayButtonFor(user)}</Table.Cell>
            </Table.Row>
        )
    }

    displayButtonFor(user) {
        const userIsAdmin = user.is_admin;
        const disabled = authService.isTheUserLogged(user);
        const adminProps = { color: 'green', text: 'Admin' } ;
        const noAdminProps = { color: 'teal', text: 'Pino' };
        const props = userIsAdmin ? adminProps : noAdminProps;
        return <Button type='submit' disabled={disabled} floated='left' color={props.color} onClick={() => this.changeUserRole(user, !userIsAdmin)}>{props.text}</Button>;
    }

    changeUserRole(aUser, bool) {
        this.setState({loading:true} )
        HardwareAppAPI.changeUserRole(aUser.id, bool)
            .then((user) => this.props.refreshUser(user))
            .then(() => this.setState({loading: false}))
    }

}

export default UserRow;
