import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import ComputerList from "./Components/ComputerList"
import moment from "moment";
import routes from "./Routes";
import AppHeader from './Components/AppHeader'
import Login from './Components/Login'
import PrivateRoute from "./PrivateRoute";
import Token from "./Components/Token";
import UserList from "./Components/UserList";
import RotationList from "./Components/RotationList";
import RotationParams from "./Components/RotationParams";
import {ComputerEditing} from "./Components/ComputerEditing";
import PineList from "./Components/PineList";
import {Summary} from "./Components/Summary";
import NotFound from "./NotFound";
import DevicePage from "./Components/DevicePage";
import DeviceOrdersPage from "./Components/DeviceOrdersPage";
import PayrollReportPage from "./Components/PayrollReportPage";

export function currentDate() {
    return moment()
}

export const oldComputersWarningDate = moment().subtract(20, 'years');

export const oldComputersDateString = oldComputersWarningDate.format("DD-MM-YYYY");

class App extends Component {
    render() {
        return (
            <div className={'AppBackground'}>
                <BrowserRouter>
                    <div>
                        <AppHeader/>
                        <main className={'main'}>

                            <Switch>
                                <Route exact path={routes.login} render={() =>
                                    <Login/>
                                }/>
                                <Route exact path={routes.token} render={() =>
                                    <Token/>
                                }/>

                                <PrivateRoute path={routes.computers} component={ComputerList}/>

                                <PrivateRoute exact path={routes.edit} component={ComputerEditing}/>

                                <PrivateRoute exact path={routes.showDevice} component={DevicePage}/>

                                <PrivateRoute exact path={routes.userlist} component={UserList}/>

                                <PrivateRoute exact path={routes.rotations} component={RotationList}/>

                                <PrivateRoute exact path={routes.pines} component={PineList}/>

                                <PrivateRoute exact path={routes.rotationsParams} component={RotationParams}/>

                                <PrivateRoute exact path={routes.summary} component={Summary}/>

                                <PrivateRoute exact path={routes.deviceOrders} component={DeviceOrdersPage}/>

                                <PrivateRoute exact path={routes.payrollReport} component={PayrollReportPage}/>

                                <Route path={'/'}>
                                    <NotFound/>
                                </Route>
                            </Switch>
                        </main>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
