import React, {Component} from "react";
import {Dropdown} from "semantic-ui-react";
import {computerStatusOptionsForDropdown} from "../services/ComputerStatus";

export default class ComputerStatusDropdown extends Component {

    render() {
        return (
            <div>
                <Dropdown
                    value={this.props.computer.status}
                    placeholder='Estado'
                    search
                    selection
                    options={computerStatusOptionsForDropdown()}
                    onChange={(even, data) => this.props.onChange(data.value)}
                />
            </div>
        )
    }
}