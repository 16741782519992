import React, {Component} from 'react'
import {Message} from "semantic-ui-react";
import {currentDate, oldComputersDateString, oldComputersWarningDate} from "../App";

export default class DateWarningMessages extends Component{
    render() {
        return <div>
            <Message visible={this.isFutureDateOfPurchase()} warning>
                <Message.Header>Advertencia: La fecha de compra seleccionada es posterior a la fecha actual</Message.Header>
            </Message>

            <Message visible={this.isAnOldComputer()} warning>
                <Message.Header>{"Advertencia: La fecha de compra que estás ingresando es anterior al " + oldComputersDateString } </Message.Header>
            </Message>
        </div>
    }

    isFutureDateOfPurchase() {
        return this.props.dateOfPurchase.isAfter(currentDate())
    }

    isAnOldComputer() {
        return this.props.dateOfPurchase.isBefore(oldComputersWarningDate)
    }

}
