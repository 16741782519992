import React, {useState} from "react";
import {Button, Grid, Header, Icon, Modal} from 'semantic-ui-react'
import moment from "moment";
import '../Css/PineRotations.css'
import {Link} from "react-router-dom";

export const PineRotations = (props) => {
    const [open, setOpen] = useState(false)
    return (
        <Modal onClose={() => setOpen(false)}
               onOpen={() => setOpen(true)}
               open={open}
               trigger={<Button circular icon='list'/>}>
            <Modal.Header>
                <span>{props.pine.name}</span>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header>Rotaciones:</Header>
                    <div>
                        <RotationTable pine={props.pine} />
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => setOpen(false)}>
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}


const ShowRotation = (props) => {

    const computer = props.rotation.computer
    return (
        <Grid.Column className="rotation-list">
            <Icon fitted name='dot circle'/>
            El {moment(props.rotation.rotation_date).format("DD-MM-yyyy")} se le
            asignó una pc
            <Link className={'device-link'}
                  to={`/dispositivo/${computer.id}`}> {computer.model.brand} - {computer.model.name} </Link>
            {props.message}
        </Grid.Column>);
}

const RotationTable = (props) => {

    const statusComputer = (rotation) => {
        if (rotation.is_first_assignation) {
            return "nueva"
        }
        return "usada"
    }


    const isFirstRotation = rotationNumber => rotationNumber === props.pine.rotations.length - 1;

    const rotationDescription = (rotation, rotationNumber) => {
        if (isFirstRotation(rotationNumber)) {
            return "que es su primer pc"
        } else {
            if (!rotation.pine_assigned_before) {
                return `que es ${statusComputer(rotation)}`
            } else {
                return `que antes la tenia ${rotation.pine_assigned_before.name}`
            }
        }
    };

    return (
        <Grid columns={1}>
            <Grid.Row>
                {props.pine.rotations.map((rotation, index) => {

                    return <ShowRotation rotation={rotation}
                                         message={rotationDescription(rotation, index)}
                    />

                })}

            </Grid.Row>

        </Grid>
    )
}


