import React, {useEffect, useState} from 'react';
import CreateDeviceOrderAcordion from "./CreateDeviceOrderAcordion";
import {
    Card, Container, Header,
    CardContent,
    Modal,
    Button,
    Input,
    TextArea,
    Form
} from "semantic-ui-react";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import '../Css/DeviceOrders.css'
import HardwareAppAPI from "../services/HardwareAppAPI";
import PinesDropdown from "./PinesDropdown";
import DeviceOrdersViewModal from "./DeviceOrdersViewModal";


const Order = (props) => {

    const [editOpen, setEditOpen] = useState(false)

    const [orderDescription, setOrderDescription] = useState(props.order.description)
    const [orderNotes, setOrderNotes] = useState(props.order.notes)
    const [pines, setPines] = useState([])
    const [orderPineId, setOrderPineId] = useState(props.order.pine.id)

    const updateOrder = () => {
        const orderToUpdated = {
            description: orderDescription,
            notes: orderNotes,
            pine_id: orderPineId,
            pine: findPineByID(orderPineId)
        }
        HardwareAppAPI.updateDeviceOrder(props.order.id, orderToUpdated)
            .then(() => {
                setEditOpen(false)
                props.onOrderChange(props.order.id, orderToUpdated)
            })
    }

    const findPineByID = (id) => pines.find(pine => pine.id === id)

    const pinesForDropdownComponent = pines.map(pine => { return {key: pine.id, text: pine.name, value: pine.id}; });

    useEffect(() => {
        HardwareAppAPI.getPines().then(pines => setPines(pines))
    }, [])


    return <>
        <Draggable draggableId={props.order.id.toString()} index={props.index}>
            {provided => (
                <div className={'order-card'}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                     ref={provided.innerRef}
                >
                    <Card>
                        <Card.Content>
                            <Card.Header>{props.order.description}</Card.Header>
                            <Card.Description>Pedido por: {props.order.pine.name}</Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <DeviceOrdersViewModal order={props.order}/>
                            <Button color='green' circular icon='edit' floated='right' onClick={() => setEditOpen(true)}/>
                        </Card.Content>
                    </Card>

                </div>
            )}
        </Draggable>

        <Modal size='tiny' open={editOpen} onClose={() => setEditOpen(false)}>
            <Modal.Header>
                <Input style={{width: '100%'}} value={orderDescription} onChange={(event) => setOrderDescription(event.target.value)}/>
            </Modal.Header>
            <Modal.Content>
                    <TextArea className={'order-description-field'} value={orderNotes} onChange={(event) => setOrderNotes(event.target.value)}/>
                <Modal.Description>
                    <PinesDropdown pineId={orderPineId} pines={pinesForDropdownComponent}
                                   whenSelect={(event, data) => setOrderPineId(data.value)}/>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setEditOpen(false)}>
                    Cancelar
                </Button>
                <Button positive onClick={updateOrder}>
                    Guardar
                </Button>
            </Modal.Actions>
        </Modal>
    </>
}

const Column = (props) => {

    return <div className={'columns-states'}>
        <Header textAlign={"center"}>
            {props.column.title}
        </Header>
        <Droppable droppableId={props.column.id}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {props.orders.map((order, index) => (
                        <Order key={order.id.toString()} order={order} index={index}
                               onOrderChange={props.onOrderChange}/>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </div>

}

const DeviceOrdersPage = (props) => {

    const [showPanel, setShowPanel] = useState(false)

    const [orders, setOrders] = useState([])

    const [statuses, setStatuses] = useState([])

    useEffect(() => {
        HardwareAppAPI.getDeviceOrders().then(orders => setOrders(orders.device_orders));
        HardwareAppAPI.getDeviceOrdersStatuses().then(statuses => setStatuses(statuses))
    }, [])

    const columns = (statuses) => {
        return statuses.map(status => {
            return {title: status.name, id: status.id.toString()}
        })
    }

    const toggleShowPanel = () => {
        setShowPanel(!showPanel)
    }

    const findOrdersByStatus = (status) => {
        return orders.filter(order => order.order_status.name === status)
    }

    const getColumnById = (id) => {
        return columns(statuses).find(column => column.id === id)
    }

    const getOrderById = (id) => {
        return orders.find(order => order.id.toString() === id)
    }

    const onOrderChange = (id, anOrder) => {
        debugger
        const updatedOrders = orders.map(order => (order.id === id) ? {
            ...order,
            description: anOrder.description,
            notes: anOrder.notes,
            pine_id: anOrder.pine_id,
            pine: anOrder.pine
        } : order)
        setOrders(updatedOrders)
    }

    const onDragEnd = result => {
        const {destination, draggableId} = result;

        if (!!destination) {
            const order = getOrderById(draggableId)
            const columnFinish = getColumnById(destination.droppableId);
            HardwareAppAPI.updateStatus(order.id, columnFinish.title)
            const updatedOrders = orders.map(anOrder => {
                return (anOrder.id !== order.id) ? anOrder : {
                    ...order,
                    order_status: statuses.find(status => status.name === columnFinish.title)
                }
            })
            setOrders(updatedOrders)
        }

    }

    return (<Container>
        <CreateDeviceOrderAcordion showPanel={showPanel} toggleShowPanel={() => toggleShowPanel()}
                                   history={props.history}/>
        <DragDropContext onDragEnd={onDragEnd} style={{position: 'fixed'}}>
            <div className={'states-list'}>
                {columns(statuses).map(column => <Column key={column.id} column={column}
                                                         orders={findOrdersByStatus(column.title).reverse()} onOrderChange={onOrderChange}/>)}
            </div>
        </DragDropContext>
    </Container>)
}

export default DeviceOrdersPage;

