import {Form} from "semantic-ui-react";
import React from "react";


const PinesDropdown = (props) => {

    return <Form.Field required>
        <Form.Dropdown
            required
            placeholder='Pedido por '
            label='Pedido por '
            fluid
            search
            value={props.pineId}
            selection
            options={props.pines}
            onChange={props.whenSelect}
        />
    </Form.Field>
}
export default PinesDropdown;