import React, {Component} from "react";
import {Button, Confirm, Form, Grid, Header, Input, TextArea} from "semantic-ui-react";
import HardwareAppAPI from "../services/HardwareAppAPI";
import EncryptedSelector from "./EncryptedSelector";
import routes from "../Routes";
import {Redirect, withRouter} from "react-router-dom";
import Loading from "./Loading";
import '../table-stylesheet.css';
import RenderForUser from "./RenderForUser";
import DropdownAutocomplete from "./DropdownAutocomplete";
import AssignDropdown from "./AssignDropdown";
import EncryptionStates from "../services/EncryptionStates";
import { A_LA_VENTA, BACK_UP, DADA_DE_BAJA, stringToDropdownOption } from "../services/ComputerStatus";
import InsuranceDropdown from "./InsuranceDropdown";
import ColumnWithLabel from "./Commons/ColumnWithLabel";
import {DateInput} from "semantic-ui-calendar-react";
import ComputerStatusDropdown from "./ComputerStatusDropdown";
import {ComputerItemDropdown} from "./ComputerItemDropdown";
import ModalConfirmDelete from "./ModalConfirmDelete";
import {extractBrands, extractModels} from "./ComputerList";


export class ComputerEditing extends Component {
    initialBrand = '';
    initialModel = '';

    state = {
        computer: {
            model: {brand: '', name: ''},
            network_card: '',
            serial_number: '',
            encryption: '',
            date_of_purchase: '',
            cost: '',
            status: '',
            location: '',
            assignment: {id: '', name: ''},
            notes: ''
        },
        pines: [],
        models: [],
        brands: [],
        network_cards: [],
        open_message_response: false,
        message_response: ""
    };

    componentDidMount() {
        HardwareAppAPI.getPines()
            .then((pines => {
                this.setState({pines: this.pinesToPineName(pines)})
            }))
            .catch(() => this.redirectToLogin(''))

        HardwareAppAPI.getComputersInfo({},'0', {column: 'date_of_purchase', order: 'descending'})
            .then(({brandsWithModels, network_cards}) => {
                const brands = extractBrands(brandsWithModels)
                const models = extractModels(brandsWithModels)
                this.setState({brands, models, network_cards})
            })
    }

    render() {
        return <RenderForUser user={this.props.user} whenAdmin={this.whenAdmin} whenPino={this.whenPino}
        />
    }

    whenAdmin = () => {
        return <Loading actionPromise={this.loadComputerAttributes}
                        whenLoaded={this.showEditionView}/>
    };

    whenPino = () => {
        return <Redirect to={routes.computers}/>
    };


    getComputerId() {
        return this.props.match.params.id;
    }

    loadComputerAttributes = () => {
        return HardwareAppAPI.getComputerById(this.getComputerId()).then((computer) => {
            this.initialBrand = computer.model.brand;
            this.initialModel = computer.model.name;
            this.setState({computer})
        })
    };

    showEditionView = () => {
        return <div className={'EditComputerBackground'}>
            <br/>
            <Header as='h1' dividing textAlign={'center'}>
                Editando {this.initialBrand + ' ' + this.initialModel}
            </Header>
            <br/>

            {this.showEditionForm()}
            {this.messageResponseAction()}
        </div>
    };

    cancelEdition = (reason,redirectedRoute) => {
        alert(reason)
        return this.props.history.push(redirectedRoute)
    };

    handleChange(keyOfState, value) {
        this.setState(prevState => ({
            computer: {
                ...prevState.computer,
                [keyOfState]: value,
            }
        }))
    }

    handleStatusChange(newStatus){
        if (this.statusWithoutAssignment(newStatus)) {
            this.handleChange('assignment', null)
        }
        this.handleChange('status', newStatus)
    }

    statusWithoutAssignment(aStatus) {
        return [BACK_UP,DADA_DE_BAJA,A_LA_VENTA].includes(aStatus)
    }

    handleSubmit = () => {
        HardwareAppAPI.updateComputerById(this.getComputerId(), this.state.computer)
            .then(() => this.confirmEdition())
            .catch(({status, message}) => {
                if (status >= 500) {
                    this.cancelEdition('Hubo un problema en el servidor al querer actualizar la computadora.',`/edit/${this.state.computer.id}`)
                } else {
                    alert(message)
                }
            })
    };

    redirectToLogin = message => {
        this.props.history.push(`/?error=${message}`)
    };

    createFieldForForm(type, keyOfState, label, placeHolder, value, isRequired, control, min) {
        const updateState = (event) => this.handleChange(keyOfState, event.target.value);
        return this.createFieldForm(type, updateState, label, placeHolder, value, isRequired, control, min);
    }

    createFieldForFormDropdownAutocomplete(options, keyOfState, callbackKeyOfState, label, placeHolder, value) {
        return <ColumnWithLabel label={label}>
            <DropdownAutocomplete options={options}
                                  value={value}
                                  whenSelect={(value) => this.handleChange(keyOfState, value)}
                                  whenAddingElement={(value) => this.props.whenAddingElement(callbackKeyOfState, value)}
                                  placeHolder={label}
                                  keyOfState={callbackKeyOfState}/>
        </ColumnWithLabel>
    }

    confirmEdition = () => {
        alert("La computadora fue actualizada!");
        return this.props.history.push(routes.computers)
    };

    showEditionForm = () => {
        const computer = this.state.computer;
        return <Form onSubmit={this.handleSubmit}>>
            <Grid centered divided='vertically' relaxed="very" stackable>

                <ColumnWithLabel label={'Marca'}>
                    <ComputerItemDropdown value={computer.model.brand}
                                          options={this.state.brands}
                                          whenSelect={this.updateBrand}
                                          whenAddingElement={(newValue) => this.addElementToStateLists('brands', newValue)}/>
                </ColumnWithLabel>

                <ColumnWithLabel label={'Modelo'}>
                    <ComputerItemDropdown value={computer.model.name}
                                          options={this.state.models}
                                          whenSelect={this.updateModel}
                                          whenAddingElement={(newValue) => this.addElementToStateLists('models', newValue)}/>
                </ColumnWithLabel>

                {this.displayDateSelector()}

                <ColumnWithLabel label={'Placa de red'}>
                    <ComputerItemDropdown value={computer.network_card}
                                          options={this.state.network_cards}
                                          whenSelect={(event, data) => this.handleChange('network_card', data.value)}
                                          whenAddingElement={(newValue) => this.addElementToStateLists('network_cards', newValue)}/>
                </ColumnWithLabel>

                {this.createFieldForForm('text', 'serial_number', 'Número de serie', 'Número de serie', computer.serial_number, false, Input)}

                {this.displayEncryptedOptions()}

                {this.createFieldForForm('number', 'cost', 'Costo', 'Costo', computer.cost, false, Input, 0)}

                {this.displayComputerStatus(computer)}

                {this.displayAssignmentOptions(computer)}

                {this.createFieldForForm('text', 'location', 'Ubicación', 'Ubicación', computer.location, false, Input)}

                {this.createFieldForForm('text', 'owner', 'Dueño', 'Dueño', computer.owner, true, Input)}

                <ColumnWithLabel label={"Seguro"}>
                    <InsuranceDropdown value={computer.insurance_status}
                                       onChange={(newStatus) => this.handleChange("insurance_status", newStatus)}/>
                </ColumnWithLabel>

                {this.createFieldForForm('text', 'notes', 'Notas', 'Notas', computer.notes, false, TextArea)}

                {this.displayButtons()}
            </Grid>
        </Form>
    };

    displayComputerStatus(computer) {
        return <ColumnWithLabel label='Estado'>
            <ComputerStatusDropdown
                onChange={(newStatus) => { this.handleStatusChange(newStatus)} }
                computer={computer}/>
        </ColumnWithLabel>
    }

    displayAssignmentOptions(computer) {
        return <ColumnWithLabel label='Asignación'>
            <AssignDropdown value={computer.assignment && computer.assignment.name}
                            allowAdditions={true}
                            onAddPine={this.addPine}
                            onChange={(newAssignmentName) => this.handleChange('assignment', {name: newAssignmentName})}
                            pinos={this.state.pines}
                            hasComputerAssigned={() => false}/>
        </ColumnWithLabel>
    }

    addElementToStateLists = (keyOfState, value) => {
        this.setState({[keyOfState]: [...this.state[keyOfState], value]})
    };

    addPine = (pine_name) => {
        const pine_option = {
            key: pine_name,
            text: pine_name,
            name: pine_name
        };
        this.setState({pines: this.state.pines.concat(pine_option)})
    }

    changeAssignment(newAssignment) {
        this.setState(prevState => ({
            computer: {
                ...prevState.computer,
                assignment: newAssignment,
            }
        }))
    }

    displayDateSelector = () => {
        return <ColumnWithLabel label={"Fecha de compra"}>
            <DateInput placeholder="Fecha de compra"
                       pickerStyle={{display: "block"}}
                       value={this.getDateOfPurchase()}
                       onChange={(event, date) => this.handleChange('date_of_purchase', date.value)}/>
        </ColumnWithLabel>;
    };

    getDateOfPurchase() {
        const computer = this.state.computer;
        const dateOfPurchase = computer.date_of_purchase;
        const default_purchase_date = '2000-01-01';
        return dateOfPurchase === default_purchase_date ? '' : dateOfPurchase;
    }


    displayEncryptedOptions = () => {
        const computer = this.state.computer
        return <ColumnWithLabel label={"¿Está encriptada?"}>
            <EncryptedSelector encryption={computer.encryption || EncryptionStates.NO_ENCRYPTION}
                               whenSelect={(value) => this.handleChange('encryption', value)}/>
        </ColumnWithLabel>;
    };

    displayButtons = () => {
        return <Grid.Row>
            <Grid.Column width={2}>
                <Button type='submit' positive>Guardar los cambios</Button>
            </Grid.Column>
            <Grid.Column width={2}>
                <Button type='button' onClick={() => this.cancelEdition("La edición fue cancelada", routes.computers)}>
                    Cancelar la edición
                </Button>
            </Grid.Column>
            <Grid.Column width={2}>
                <ModalConfirmDelete trigger={<Button type='button' negative>Eliminar PC</Button>}
                                            header={'Eliminar computadora'}
                                            confirmDeleteFunction={this.confirmDelete}
                                            modalContent={this.modalContent()}/>
            </Grid.Column>
        </Grid.Row>
    };

    messageResponseAction() {
        return <Confirm open={this.state.open_message_response}
                        header={this.state.message_response}
                        content={null}
                        cancelButton={null}
                        confirmButton={"Volver"}
                        onConfirm={() => {
                            this.props.history.push(routes.computers);
                        }}
        />;
    }

    modalContent = () => {
        const computer = this.state.computer
        const assignment = computer.assignment
        const confirmationMessage =  <p> ¿Está seguro que desea eliminar la computadora? </p>
        const computerStatus=  assignment ? <p>La computadora actualmente está asignada a <b>{assignment.name}</b></p> : <p>La computadora actualmente se encuentra en <b>{computer.status}</b></p>
        return <> {confirmationMessage} {computerStatus}</>
    }
    confirmDelete = () => {
        HardwareAppAPI.deleteComputer(this.state.computer.id)
            .then(response => {
                this.setState({open_message_response: true, message_response: "Computadora eliminada correctamente."})
            })
            .catch(({status, message}) =>{
                if (status >= 500) {
                    this.setState({open_message_response: true, message_response: "Hubo un problema en el servidor al querer eliminar la computadora."})
                } else {
                    this.setState({open_message_response: true, message_response: message})
                }
            })
    };

    pinesToPineName = (pines) => pines.map(pine => stringToDropdownOption(pine.name));


    createFieldForm(type, updateState, label, placeHolder, value, isRequired, control, min) {
        return <ColumnWithLabel label={label}>
            <Form.Field required={isRequired} type={type} min={min}
                        onChange={updateState}
                        control={control}
                        placeholder={placeHolder}
                        value={value}/>
        </ColumnWithLabel>
    }

    updateModel = (event, data) => {
        this.handleChange('model', {
            name: data.value,
            brand: this.state.computer.model.brand
        });
    }

    updateBrand = (event, data) => {
        this.handleChange('model', {
            name: this.state.computer.model.name,
            brand: data.value
        })
    }

}

export default withRouter(ComputerEditing)
