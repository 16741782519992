import React from 'react'
import {Accordion, Button, Container, Form, Icon, Segment} from "semantic-ui-react";
import HardwareAppAPI from "../services/HardwareAppAPI";
import {DateInput} from "semantic-ui-calendar-react";

class AddPine extends React.PureComponent {
    state = {
        active_add_form: false,
        first_day_of_work: ''
    };

    render() {
        return <Accordion styled fluid>
            <Accordion.Title active={this.state.active_add_form}
                             onClick={() => this.setState({active_add_form: !this.state.active_add_form})}>
                <Icon name='dropdown'/>
                Agregar Pino
            </Accordion.Title>
            <Accordion.Content active={this.state.active_add_form}>
                <Segment raised>
                    <Form onSubmit={(event) => this.handleSubmit()}>
                        <Form.Field required>
                            <label>Nombre del pino</label>
                            <input required placeholder='Nombre'
                                   onChange={(event) => this.setState({pine_name_to_add: event.target.value})}
                                   value={this.state.pine_name_to_add}/>
                        </Form.Field>
                        {this.createDatePicker()}
                        <Form.Group>
                            <Container>
                                <Button type='submit' floated='right' color='green'>Agregar</Button>
                            </Container>
                        </Form.Group>
                    </Form>
                </Segment>
            </Accordion.Content>
        </Accordion>
    }

    handleSubmit = () => {
        HardwareAppAPI.addPine(this.state.pine_name_to_add, this.state.first_day_of_work)
            .then(this.props.onAddPine)
            .then(() => this.setState({pine_name_to_add: '', first_day_of_work: ''}))
    };


    handleChange(keyOfState, value) {
        this.setState({[keyOfState]: value})
    }

    createDatePicker() {
        return <DateInput closable={true}
                          label='Fecha de Ingreso del Pino'
                          placeholder='Fecha de Ingreso'
                          value={this.state.first_day_of_work}
                          required
                          animation={'none'}
                          pickerStyle={{display: 'block'}}
                          onChange={(event, {value}) => this.handleChange('first_day_of_work', value)}/>
    }
}

export default AddPine
