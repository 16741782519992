import React, {Component} from 'react'
import {Form} from "semantic-ui-react";
import EncryptionStates from "../services/EncryptionStates";

export default class EncryptedSelector extends Component {

    render() {
        return <Form.Group inline>
                {this.createEncryptionCheckbox('Full', EncryptionStates.FULL)}
                {this.createEncryptionCheckbox('Solo Home', EncryptionStates.ONLY_HOME)}
                {this.createEncryptionCheckbox('No', EncryptionStates.NO_ENCRYPTION)}
        </Form.Group>
    }

    createEncryptionCheckbox(label, value) {
        return <Form.Checkbox
            radio
            label={label}
            value={value}
            checked={this.props.encryption === value}
            onChange={(event) => this.props.whenSelect( value)}
        />


    }
}

