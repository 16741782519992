import React, {Component} from 'react'
import HardwareAppAPI from "../services/HardwareAppAPI";
import {Container, Table} from "semantic-ui-react";
import UserRow from "./UserRow";

class UserList extends Component {

    constructor(props) {
        super(props);
        this.generateListRows = this.generateListRows.bind(this);
        this.refreshUser = this.refreshUser.bind(this);
        this.state = {
            users: []
        };
    }

    render() {
        return (
            <Container text>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Usuario</Table.HeaderCell>
                            <Table.HeaderCell>Acciones Posibles</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.generateListRows()}
                    </Table.Body>
                </Table>
            </Container>
        )
    }

    generateListRows() {
        return this.state.users.map((user) => <UserRow user={user} refreshUser={this.refreshUser}/>);
    };

    componentDidMount() {
        HardwareAppAPI.getUsers().then(
            (users) => {
                this.setState({users})
            }
        )
    }


    refreshUser(user) {
        const users = this.state.users;
        const isTheUser = anUser => anUser.id === user.id;
        const updatedUsers = users.map( anUser => isTheUser(anUser) ? user : anUser);
        this.setState({ users: updatedUsers});
    };

}

export default UserList;
