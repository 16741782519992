import {authService} from "./AuthService";

export default class HardwareAppAPI {
    static API_USERS = '/api/users';
    static API_COMPUTERS = '/api/computers';
    static API_DEVICE_ORDERS = '/api/device_orders'
    static API_MODELS = '/api/models';
    static API_ROTATIONS = '/api/rotations';
    static API_PINES = '/api/pines';
    static API_PAYROLL_REPORT = '/api/payroll_report';
    static API_ORDER_STATUS = '/api/order_status'
    static RESPONSE = response => response.json().then((json) => response.ok ? json : Promise.reject(json))

    static headers() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authService.getToken(),
        }
    }

    static verifyAuth(fetchPromise) {
        return fetchPromise.then(res => {
            if (res.status !== 403) {
                return res
            } else {
                authService.removeToken();
                return Promise.reject(403);
            }
        })
    }
    static getComputersInfo(filter,page,{order,column}) {
        const actualOrder = order === 'descending' ? 'desc' : 'asc';
        const endpoint = `${this.API_COMPUTERS}?page=${page}&${this.formatQueryParamsForFilter(filter)}&orderColumn=${column}&order=${actualOrder}`;
        return fetch(endpoint, {headers: this.headers()})
            .then(this.RESPONSE)
    }
    static getPayrollReport(selectedStates,includeDate) {
        let filename = "";
        return this.verifyAuth(fetch(this.API_PAYROLL_REPORT +'?' + this.formatQueryParamsForPayrollReport(selectedStates,includeDate), {headers: this.headers()}))
            .then(results => {
                filename = results.headers.get("content-disposition").split('"')[1];
                return results.blob()})
            .then(blob => {
                return { blob: new Blob([blob]), filename }})
    }

    static changeAssignment(computer, assigned_pine) {
        return this.verifyAuth(fetch(this.API_COMPUTERS + '/' + computer.id + '/assignment', {
            method: 'PUT',
            headers: this.headers(),
            body: JSON.stringify({pine_assigned_name: assigned_pine.name})
        })).then(this.RESPONSE).then(json => json.computer)
    }

    static getComputerById(id) {
        const endpoint = `${this.API_COMPUTERS}/${id}}`;
        return fetch(endpoint, {headers: this.headers()})
            .then(this.RESPONSE)
            .then((json) => json.computer)
    }

    static addComputers(computers) {
        return this.verifyAuth(fetch(this.API_COMPUTERS, {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify({computers})
        }))
            .then(this.RESPONSE)
    }
    static deleteComputer(computer_id) {
        const endpoint = `${this.API_COMPUTERS}/${computer_id}`;
        return fetch(endpoint, {
            method: 'DELETE',
            headers: this.headers(),
        }).then(this.RESPONSE)
    }
    static addDeviceOrder(deviceOrder){
        return fetch(this.API_DEVICE_ORDERS, {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify(deviceOrder)
        })
            .then(this.RESPONSE)
    }

    static getDeviceOrders(){
        return fetch(this.API_DEVICE_ORDERS, {
            method: 'GET',
            headers: this.headers()
        })
            .then(this.RESPONSE)
    }

    static updateDeviceOrder(id, newDeviceOrderData) {
        return fetch(this.API_DEVICE_ORDERS  + `/${id}`, {
            method: 'PATCH',
            headers: this.headers(),
            body: JSON.stringify(newDeviceOrderData)
        })
            .then(this.RESPONSE)
    }


    static formatQueryParamsForFilter(filter) {
        return (encodeURI(
            this.attributes(filter)
                .map(field => field + "=" + filter[field])
                .join('&')
        ))
    }

    static formatQueryParamsForPayrollReport(states,includesDate) {
        let queryParams = ""
        states.forEach(state => queryParams += "states[]=" + state + "&")
        includesDate ? queryParams += "includeDate=" + includesDate : queryParams = queryParams.slice(0, -1)
        return queryParams
    }

    static attributes(obj) {
        return Object.keys(obj).filter(key => obj[key] !== null && obj[key] !== undefined)
    }

    static filterComputers(filter) {
        return fetch(this.API_COMPUTERS +'?' + this.formatQueryParamsForFilter(filter), {headers: this.headers()})
            .then(this.RESPONSE)
            .then(json => json.computers)
    }

    static getPines(){
        return this.verifyAuth(fetch(this.API_PINES, {
            method: 'GET',
            headers: this.headers()
        }))
            .then(this.RESPONSE)
            .then(json => json.pines)
    }

    static getProcessPines() {
        return this.getPines()
            .then(pines => processPines(pines))
    }

    static changeComputerState(computer, status) {
        return fetch(this.API_COMPUTERS + `/${computer.id}/status`, {
            method: 'PUT',
            headers: this.headers(),
            body: JSON.stringify({status})
        })
            .then(this.RESPONSE)
            .then(json => json.computer)
    }


    static updateComputerById(id, computer) {
        return this.verifyAuth(fetch(this.API_COMPUTERS + `/${id}`, {
            method: 'PATCH',
            headers: this.headers(),
            body: JSON.stringify(computer)
        }))
            .then(this.RESPONSE)
    }

    static changeInsuranceStatus(computer, value) {
        return fetch(this.API_COMPUTERS + '/' + computer.id, {
            method: 'PUT',
            headers: this.headers(),
            body: JSON.stringify({insurance_status: value})
        }).then(this.RESPONSE).then(json => json.computer)
    }

    static getStatusesOptions() {
        return ['Asegurada', 'No asegurada', 'Dar de Baja', 'Dando de Baja'].map( label => ({
            key: label,
            value: label,
            text: label
        }))
    }

    static getComputerEvents(computer) {
        const eventsPath = this.API_COMPUTERS+`/${computer.id}/events`;
        return fetch(eventsPath, {
            method: 'GET',
            headers: this.headers()
        }).then(this.RESPONSE).then(json => json.events)
    }
    static getUsers() {
        return this.verifyAuth(fetch(this.API_USERS, {
            method: 'GET',
            headers: this.headers()
        })).then(res => res.json()).then(json => json.users)
    }
    static changeUserRole(user_id,isAdmin) {
        return this.verifyAuth(fetch(this.API_USERS +`/${user_id}`, {
            method: 'PUT',
            headers: this.headers(),
            body: JSON.stringify({is_admin: isAdmin})
        })).then(res => res.json()).then(json => json.user)
    }

    static getModels() {
        return this.verifyAuth(fetch(this.API_MODELS, {
            method: 'GET',
            headers: this.headers(),
        })).then(this.RESPONSE)
    }
    static updateModel(model) {
        return this.verifyAuth(fetch(this.API_MODELS , {
            method: 'PATCH',
            headers: this.headers(),
            body: JSON.stringify(model)
        }))
            .then(this.RESPONSE)
            .then(json => json.model)
    }
    static getPinesByRotations(){
        return this.verifyAuth(fetch(this.API_ROTATIONS, {
            method: 'GET',
            headers: this.headers(),
        })).then(this.RESPONSE)
            .then(json =>json.rotations)

    }

    static updatePine(pine) {
        return this.verifyAuth(fetch(this.API_PINES + '/' + pine.id, {
            method: 'PUT',
            headers: this.headers(),
            body: JSON.stringify({name: pine.name})
        }))
            .then(this.RESPONSE)
            .then(json => json.pine)
    }
    static deletePine(pine) {
        return this.verifyAuth(fetch(this.API_PINES + '/' + pine.id, {
            method: 'DELETE',
            headers: this.headers(),
        }))

    }
    static addPine(pine_name,first_day_of_work){
        return this.verifyAuth(fetch(this.API_PINES, {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify({name: pine_name, first_day_of_work: first_day_of_work})
        }))
            .then(this.RESPONSE)
            .then(json => json.pine)

    }

    static getAllComputers() {
        return this.verifyAuth(fetch(this.API_COMPUTERS, {
            method: 'GET',
            headers: this.headers()
        }))
            .then(this.RESPONSE)
            .then(computers => computers.computers);
    }

    static getDeviceOrdersStatuses() {
        return   this.verifyAuth(fetch('/api/order_statuses', {
            method: 'GET',
            headers: this.headers(),
        })).then(this.RESPONSE).then(json=>json.order_statuses)
    }

    static updateStatus(order_id,status){
        return fetch(this.API_DEVICE_ORDERS + '/' + order_id + '/status', {
            method: 'PUT',
            headers: this.headers(),
            body: JSON.stringify({status: status})
        }).then(this.RESPONSE)
    }

}
export function processPines(pines){
    return pines.map(pine => {
        return {key: pine.id, text: pine.name, value: pine};
    });
}

