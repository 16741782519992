import React from "react";
import {List, Segment} from "semantic-ui-react";

export class FilterText extends React.PureComponent {


    render() {
        return this.filteredFields().length > 0 ? <Segment>
            <List horizontal>
                {this.showFilteredFields()}
            </List>
        </Segment> : <React.Fragment/>
    }

    showFilteredFields() {
        return this.filteredFields()
            .map(field => <List.Item><p>{`${field.displayName}: ${field.value} `}</p></List.Item>)
    }

    filterFields(){
        const { brand, with_model, status, from_date, until_date } = this.props.filter;
        return [
            {displayName: "Marca", value: brand},
            {displayName: "Modelo", value: with_model},
            {displayName: "Estado", value: status},
            {displayName: "Desde", value: from_date},
            {displayName: "Hasta", value: until_date}
        ];
    };

    filteredFields() {
        return this.filterFields().filter((field) => field.value)
    }
}
