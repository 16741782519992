import {Button, Container, Divider, Grid, Header, Icon, Segment} from "semantic-ui-react";

import React, {Component} from 'react';
import '../device-page.css'
import QRCode from "qrcode.react";
import imagenPino from "../resources/pino-c.svg";
import HardwareAppAPI from "../services/HardwareAppAPI";
import Loading from "./Loading";
import DeviceDetailsTable from "./DeviceDetailsTable";
import EventDetailsList from "./EventDetailsList";

class DevicePage extends Component {
    state = {
        computer: {},
        events: []
    };

    render() {
        return <Loading actionPromise={this.loadComputerAttributes}
                        whenLoaded={this.showDeviceView}/>
    }

    getComputerId() {
        return this.props.match.params.id;
    }

    loadComputerAttributes = () => {
        return HardwareAppAPI.getComputerById(this.getComputerId()).then((computer) => {
            this.setState({computer})
        }).then(() => {
            this.updateEvents();
        });
    };

    updateEvents() {
        HardwareAppAPI.getComputerEvents(this.state.computer)
            .then(events => this.setState({events}))
    }

    redirectToEdition = (computer) => {
        this.props.history.push(`/edit/${computer.id}`)
    };

    showDeviceView = () => {
        const computer = this.state.computer;
        return <>
            <Container>
                <Segment>
                    <section className='DevicePageHeader'>
                        <Header as='h1' floated='right' >
                            Detalles
                        </Header>
                        <section className='DevicePageEditOption'>
                            <Button size="large" icon color='green' onClick={() => this.redirectToEdition(computer)}>
                                <Icon name='edit'/>
                                Edit
                            </Button>
                        </section>
                    </section>
                    <Divider/>
                    <Grid columns={2} padded doubling>
                        <Grid.Column align="right" width={10} floated='right' >
                            <DeviceDetailsTable computer={computer}/>
                        </Grid.Column>
                        <Grid.Column align="left" width={5} floated='left' >
                            <Segment vertical>
                                <EventDetailsList events={this.state.events}/>
                            </Segment>
                            <Segment vertical>
                                <QRCode className='DevicePageQRCode'
                                        renderAs='svg'
                                        imageSettings={{
                                            src: imagenPino,
                                            height: 50,
                                            width: 50,
                                        }}
                                        level='L'
                                        fgColor='#9FCC3B'
                                        bgColor='#FFFFFF'
                                        value={`${process.env.REACT_APP_BACKEND_URL || ''}/edit/${this.getComputerId()}`}/>
                            </Segment>
                            </Grid.Column>
                    </Grid>
                </Segment>
            </Container>
        </>
    }

}

export default DevicePage;