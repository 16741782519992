import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {authService} from "./services/AuthService";
import routes from "./Routes";

const redirectToLogin = (location) => {
    authService.removeToken();
    return <Redirect to={{pathname: routes.login, state: {from: location}}}/>

};
const PrivateRoute = ({component: Component, user, ...rest}) => {
    const isLoggedIn = authService.isLogged()
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} user={user}/>
                ) :
                    redirectToLogin(props.location)
            }
        />
    )
};


export default PrivateRoute
