import {Button, Header, Input, Modal, TextArea} from "semantic-ui-react";
import React, {useState} from "react";


const DeviceOrdersViewModal = (props) => {
    const [open, setOpen] = useState(false)

    return <Modal size='tiny'
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  open={open}
                  trigger={<Button color='green' circular icon='eye' floated='right'/>}>
        <Modal.Header>
            <span>{props.order.description}</span>
        </Modal.Header>
        <Modal.Content>
            <Modal.Description>
                {props.order.notes}
            </Modal.Description>
            <Modal.Description>
                Pedido por: {props.order.pine.name}
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setOpen(false)}>
                Cerrar
            </Button>
        </Modal.Actions>
    </Modal>
}
export default DeviceOrdersViewModal