import React from 'react'
import {Form} from "semantic-ui-react";

const DropdownAutocomplete = ({options, value, whenSelect, whenAddingElement, placeHolder, allowAdditions}) => {
    return (
            <Form.Dropdown
                label={placeHolder}
                placeholder={placeHolder}
                closeOnChange
                fluid
                search
                selection
                allowAdditions={allowAdditions}
                options={options}
                value={value}
                onChange={(event, data) => whenSelect(data.value)}
                onAddItem={(event,data) => whenAddingElement(data.value)}/>
    )
};

export const DropdownAutocompleteWithoutLabel = ({options, value, whenSelect, whenAddingElement, placeHolder, allowAdditions}) => {
    return (
        <Form.Dropdown
            placeholder={placeHolder}
            closeOnChange
            fluid
            search
            selection
            allowAdditions={allowAdditions}
            options={options}
            value={value}
            onChange={(event, data) => whenSelect(data.value)}
            onAddItem={(event,data) => whenAddingElement(data.value)}/>
    )
};

export default DropdownAutocomplete;
