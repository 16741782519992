import {Component} from "react";
import {getUserRole} from "../services/Session";

class RenderForUser extends Component {
    whenNotLoggedIn = this.props.whenNotLoggedIn || this.defaultWhenNotLoggedIn;

    render() {
        return getUserRole().acceptVisitor(this)
    }

    visitAdmin = () => {
        return this.props.whenAdmin()
    };

    visitPino = () => {
        return this.props.whenPino()
    };

    visitNoLogged = () => {
        return this.whenNotLoggedIn()
    };

    defaultWhenNotLoggedIn = () => {
        return null
    }
}

export default RenderForUser;
