import {Dropdown, Menu, Responsive} from 'semantic-ui-react'
import React, {Component} from 'react'
import '../header.css'
import {authService} from "../services/AuthService";
import routes from "../Routes";
import {withRouter} from "react-router-dom";
import RenderForUser from "./RenderForUser";
import {PineSpinner} from "./Commons/PineSpinner";
import HeaderMenu from "./HeaderMenu";

class AppHeader extends Component {

    state = {};
    menuOptionsPine = [
        {
            name: 'Computadoras', redirection: () => this.redirectToComputers(), id: 1
        },
        {
            name: 'Pedidos', redirection: () => this.redirectToDeviceOrders(), id : 2
        }
    ]

    menuOptionsAdmin = [
        {
            name: 'Computadoras', redirection: () => this.redirectToComputers(), id: 1
        },
        {
            name: 'Usuarios', redirection: ()=>this.redirectToUserList(), id: 2
        },
        {
            name: 'Rotaciones', redirection: ()=>this.redirectToRotations(), id: 3
        },
        {
            name: 'Pinos', redirection: ()=>this.redirectToPineList(), id: 4
        },
        {
            name: 'Reporte General', redirection: ()=>this.redirectToSummary(), id: 5
        },
        {
            name: 'Pedidos', redirection: ()=>this.redirectToDeviceOrders(), id : 6
        },
        {
            name: 'Reporte de nómina', redirection: () => this.redirectToPayrollReport(), id : 7
        }

    ]


    render() {
        return (
            <Menu className={'header'} inverted>
                <Menu.Item header style={{maxWidth: '40%'}}>
                    <PineSpinner/>
                    10 Pines - Jaguar
                </Menu.Item>
                <Menu secondary>
                    <RenderForUser whenAdmin={this.renderAdminMenu}
                                   whenPino={this.renderPineMenu}
                                   whenNotLoggedIn={this.renderNotLoggedMenu}/>

                </Menu>
                {this.renderUserMenu()}
            </Menu>
        )
    }

    renderUserMenu() {
        if (authService.isLogged()) {
            const user = authService.actualUser()
            return navigator.userAgent.toUpperCase().includes("MOBI") ?
                    <Dropdown item icon="user" text={user && user.user_nickname} simple>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text="Logout"
                                onClick={this.handleLogout}
                            />
                        </Dropdown.Menu>
                    </Dropdown> :
                    <Menu.Menu position='right'>
                        <Menu.Item
                            name={user && user.user_nickname}
                        />
                        <Menu.Item
                            name='logout'
                            onClick={this.handleLogout}
                        />
                    </Menu.Menu>
        }
    }

    handleLogout = () => {
        this.props.history.push(routes.login)
        authService.removeToken();
    };

    renderAdminMenu = () => {
        return <HeaderMenu menuOptions={this.menuOptionsAdmin}/>
    };


    renderNotLoggedMenu = () => {
        return null;
    };

    renderPineMenu = () =>{
        return <HeaderMenu menuOptions={this.menuOptionsPine}/>
    }

    redirectToComputers = () => this.props.history.push(routes.computers);

    redirectToUserList = () => this.props.history.push(routes.userlist);

    redirectToRotations = () => this.props.history.push(routes.rotations);

    redirectToPineList = () => this.props.history.push(routes.pines);

    redirectToSummary = () => this.props.history.push(routes.summary);

    redirectToDeviceOrders = () => this.props.history.push(routes.deviceOrders);

    redirectToPayrollReport = () => this.props.history.push(routes.payrollReport);
}



export default withRouter(AppHeader)
